import React, { useState } from "react";
import {
  TextField,
  Button,
  Avatar,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "src/redux/actions/userActions";
import { PhotoCamera, Visibility, VisibilityOff } from "@mui/icons-material";
import defaultAvatar from "../../assets/default_profile_image.png";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const account = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const firstName = account?.name?.split(" ")[0] ?? "";
  const lastName = account?.name?.split(" ")[1] ?? "";

  const [profile, setProfile] = useState({
    firstName: firstName,
    lastName: lastName,
    password: "",
    confirmPassword: "",
    image: "",
  });

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(
    account?.image ? account?.image : defaultAvatar
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEditToggle = () => setIsEditing(!isEditing);
  const handleChange = (e) =>
    setProfile({ ...profile, [e.target.name]: e.target.value });

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setProfile({ ...profile, image: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    let formData = new FormData();
    const name = profile?.firstName + " " + profile?.lastName;
    formData.append("name", name);
    formData.append("password", profile?.password);
    formData.append("password_confirmation", profile?.confirmPassword);
    if (profile?.image) {
      formData.append("image", profile?.image);
    }
    editProfile(formData);
  };

  const editProfile = async (data) => {
    setLoading(true);
    try {
      const res = await request.post(`/profile`, data);

      if (res) {
        const userData = res.data.data;
        toastify("success", res.data.message);
        setIsEditing(false);
        dispatch(
          loginAction(
            token,
            userData.id,
            userData.name,
            userData.email,
            userData.image,
            userData.user_type
          )
        );
        setLoading(false);
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      setLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: 800, mx: "auto", p: 3, textAlign: "center" }}>
      <CardContent>
        <Avatar
          src={imagePreview || defaultAvatar}
          sx={{ width: 100, height: 100, mx: "auto", mb: 2 }}
        />
        {isEditing && (
          <Tooltip title="Upload Profile Photo" arrow>
            <IconButton color="primary" component="label">
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageUpload}
              />
              <PhotoCamera />
            </IconButton>
          </Tooltip>
        )}

        {/* Profile Details */}
        <TextField
          label="First Name"
          name="firstName"
          value={profile.firstName}
          onChange={handleChange}
          fullWidth
          margin="dense"
          disabled={!isEditing}
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={profile.lastName}
          onChange={handleChange}
          fullWidth
          margin="dense"
          disabled={!isEditing}
        />
        <TextField
          label="Email"
          name="email"
          defaultValue={account?.email}
          onChange={handleChange}
          fullWidth
          margin="dense"
          readOnly
        />

        {isEditing && (
          <>
            {/* Password Field with Show/Hide Toggle */}
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={profile.password}
              onChange={handleChange}
              fullWidth
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={profile.confirmPassword}
              onChange={handleChange}
              fullWidth
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          size="large"
          fullWidth
          onClick={() => {
            if (isEditing) {
              handleSave();
            } else {
              handleEditToggle();
            }
          }}
        >
          {loading ? "Loading..." : isEditing ? "Save" : "Edit"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProfilePage;
