import React, { useRef } from "react";
import Iconify from "src/components/iconify/Iconify";
import { Close } from "@mui/icons-material";
import { getUsers, searchUser } from "./requests";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { StyledSearch } from "src/sections/dashboard/user/UserListToolbar.styles";

const SearchUser = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const searched = useSelector((state) => state.user.searched);
  const userPagination = useSelector((state) => state.user.userPagination);
  const selectedFilter = useSelector((state) => state.user.selectedFilter);

  const page =
    userPagination && Number.isInteger(userPagination?.page)
      ? userPagination?.page
      : 1;
  const rowsPerPage =
    userPagination && Number.isInteger(userPagination?.page)
      ? userPagination?.rowsPerPage
      : 25;

  const handleSubmit = (e) => {
    e.preventDefault();
    let inputValue = e.target.elements.searchUser.value;

    if (inputValue === "") {
      getUsers(dispatch, page, rowsPerPage, selectedFilter);
    } else {
      searchUser(dispatch, inputValue);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledSearch
        inputRef={inputRef}
        placeholder={"Search order..."}
        name="searchUser"
        onBlur={(e) => {
          if (e.target.value === "" && searched) {
            getUsers(dispatch, page, rowsPerPage, selectedFilter);
          }
        }}
        endAdornment={
          <>
            {searched && (
              <Tooltip title="Remove filter">
                <IconButton
                  onClick={() => {
                    getUsers(dispatch, page, rowsPerPage, selectedFilter);

                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mr: 2, bgcolor: "secondary.light" }}
            />

            <IconButton type="submit">
              <Iconify
                icon="eva:search-fill"
                cursor="pointer"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </IconButton>
          </>
        }
      />
    </form>
  );
};

export default SearchUser;
