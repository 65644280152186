import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Divider,
  styled,
  Stack,
  Button,
} from "@mui/material";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import {
  StyledRoot,
  StyledContent,
  StyledSection,
  FormContainer,
} from "../../pages/auth/login/Login.styles";
import Banner from "./Banner";
import Logo from "src/components/logo/Logo";
import FormStepper from "src/components/stepper";
import useResponsive from "src/hooks/useResponsive";
import { googleLogin } from "src/pages/auth/register/requests";
import { loadingAction, loadingBtnAction } from "src/redux/actions/userActions";

const AuthLayout = (props) => {
  const mdUp = useResponsive("up", "md");
  const myDivRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);
  const steps = ["Send OTP to Email", "Verify OTP code", "Reset Password"];
  const clientId =
    "469901868785-n9j8jifnstss57uo159onk88rpk4i218.apps.googleusercontent.com";

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
    dispatch(loadingAction(false));
    dispatch(loadingBtnAction(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (res) => {
    if (res) {
      const data = {
        email: res.profileObj.email,
        name: res.profileObj.name,
      };

      googleLogin(dispatch, navigate, data);
    }
  };

  const onFailure = (err) => {
    console.log(err, "err");
  };

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
  }));

  return (
    <>
      <Helmet>
        <title> {props.title} </title>
      </Helmet>
      <StyledRoot>
        <span ref={myDivRef} />
        <Main>
          <Container maxWidth="sm">
            <StyledContent>
              <div style={{ paddingTop: "40px" }}>
                <Logo href="/" />
              </div>

              {props.goBack && (
                <div style={{ padding: "16px 0px" }}>
                  <NavLink to={"/login"}>Back to login</NavLink>
                </div>
              )}
              {props.reset && <FormStepper steps={steps} />}

              <FormContainer props={props}>
                <Typography
                  variant="h4"
                  color={"primary.main"}
                  gutterBottom
                  mb={2}
                >
                  {props.heading}
                </Typography>

                {props.form}

                {!props.reset && (
                  <>
                    <Divider sx={{ my: 3 }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        OR
                      </Typography>
                    </Divider>
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      loading={isLoading}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      className="GOOGLE"
                    />
                  </>
                )}
                {props.login && (
                  <Typography
                    variant="body2"
                    sx={{ mt: 2, mb: 5, textAlign: "center" }}
                  >
                    Don’t have an account? {""}
                    <Link variant="subtitle2" to="/signup">
                      Sign up
                    </Link>
                  </Typography>
                )}
              </FormContainer>
            </StyledContent>
          </Container>
        </Main>

        {mdUp && (
          <StyledSection>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <NavLink to="/signup">
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    borderRadius: "25px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  Sign Up
                </Button>
              </NavLink>
            </Stack>
            <Banner />
          </StyledSection>
        )}
      </StyledRoot>
    </>
  );
};

export default AuthLayout;
