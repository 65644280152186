import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  CardMedia,
  Stack,
  Container,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import image1 from "../../../assets/home/education/education1.webp";
import image2 from "../../../assets/home/education/education2.webp";
import image3 from "../../../assets/home/education/education3.webp";

const sections = [
  {
    title: "Group Coaching Sessions",
    description:
      "Every month, you'll learn directly from Jesse in a live group coaching session. Jesse dives deep into topics that are relevant to your POD business today. Each session is available for unlimited replays and comes with a PDF of the presentation. Plus, you get to participate in a live Q&A with Jesse right after each session!",
    image: image1,
  },
  {
    title: "Strategic Product Trainings",
    description:
      "Jump into our product trainings where Jesse deep dives into exactly how to approach and leverage ICP’s strategic product types! He literally provides a cheat sheet on how to succeed with our proven product types 🤯",
    image: image2,
    reversed: true,
  },
  {
    title: "Live Growth Events",
    description:
      "Get private access to ICP’s live events where we deep dive into the best strategies and approaches to growing your business in today’s environment. Not only do you get to learn from Jesse, you also get to learn directly from our active 7-figure ICP coaches. These events will not only motivate you, but will catapult you into each season with newfound knowledge!",
    image: image3,
  },
];

const EducationSection = () => {
  const isDesktop = useResponsive("up", "md");
  return (
    <Container
      maxWidth="xl"
      sx={{
        px: { xs: 4, md: 18 },
        py: 4,
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h2" fontWeight="bold" gutterBottom>
          Inner Circle Prints Education
        </Typography>

        <Grid container spacing={4} my={4}>
          {sections.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Stack
                direction={"column"}
                justifyContent={"center"}
                textAlign={"start"}
              >
                {isDesktop && section.reversed ? (
                  <>
                    <Typography variant="h5" color="#0195E8" fontWeight={800}>
                      {section.title}
                    </Typography>
                    <Typography variant="body1">
                      {section.description}
                    </Typography>

                    <CardMedia
                      component="img"
                      sx={{
                        mt: 2,
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        border: "3px solid #000",
                      }}
                      image={section.image}
                      alt={section.title}
                    />
                  </>
                ) : (
                  <>
                    <CardMedia
                      component="img"
                      sx={{
                        mb: 2,
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        border: "3px solid #000",
                      }}
                      image={section.image}
                      alt={section.title}
                    />

                    <Typography variant="h6" color="#0195E8" fontWeight={800}>
                      {section.title}
                    </Typography>
                    <Typography variant="body1">
                      {section.description}
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>
        <NavLink to="/signup">
          <Button
            variant="contained"
            sx={{
              mt: 2,
              width: { xs: "100%", sm: "auto" },
              fontSize: { xs: "0.875rem", sm: "1rem" },
              padding: { xs: "8px", sm: "6px 16px" },
            }}
          >
            Become an Exclusive ICP Member
          </Button>
        </NavLink>
      </Box>
    </Container>
  );
};

export default EducationSection;
