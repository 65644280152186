import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { cancelPlan } from "src/pages/subscription-management/requests";

const CancelSubscription = ({ id }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Are you sure you want to cancel your subscription?
      </Typography>
      <Stack direction="row" justifyContent={"end"} spacing={2} mt={4}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => dispatch(setModalState(undefined))}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => cancelPlan(dispatch)}
          loading={isLoading}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CancelSubscription;
