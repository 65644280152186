import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";
import { useDispatch, useSelector } from "react-redux";
import DynamicMockupEditor from "src/components/mockup-editor/DynamicMockupEditor";
import { setInitialsArray } from "src/redux/actions/orderActions";
import { setModalState } from "src/redux/actions/modalActions";

const Initials = ({ item }) => {
  const dispatch = useDispatch();
  const initials = useSelector((state) => state.orders.initialsArray);

  const deleteFile = (index) => {
    const updatedFiles = [...initials];
    updatedFiles.splice(index, 1);
    dispatch(setInitialsArray(updatedFiles));
  };

  return (
    <div>
      <Typography variant="subtitle1" sx={{ my: 2 }}>
        Select Initials:
      </Typography>
      <Button
        variant="outlined"
        component="span"
        startIcon={<Iconify icon="material-symbols:image-outline"></Iconify>}
        fullWidth
        sx={{ mb: 2 }}
        onClick={() => {
          dispatch(
            setModalState(
              <DynamicMockupEditor
                mockupUUID={"a74f1b1c-3893-4cd1-ba94-2e3cc4bc3dbd"}
                type={"initials"}
                place={"initials"}
                item={item}
              />,
              true,
              { paddingLeft: 0 }
            )
          );
        }}
      >
        Upload Images
      </Button>
      {initials?.length ? (
        <Stack direction={"row"} spacing={3} mt={2} flexWrap={"wrap"}>
          {initials?.map((image, index) => (
            <DeleteImageViewer
              key={index}
              file={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              fileName={"Catalog image"}
              deleteFile={() => deleteFile(index)} // Pass index to deleteFile
            />
          ))}
        </Stack>
      ) : (
        ""
      )}
    </div>
  );
};

export default Initials;
