import { useState } from "react";
import Iconify from "src/components/iconify";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";

const AddItem = ({ orderID }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (path) => {
    setAnchorEl(null);
    if (path) {
      navigate(`/dashboard/${path}/${orderID}`);
    }
  };

  return (
    <>
      <Tooltip title="Add Item" arrow>
        <IconButton onClick={handleClick}>
          <Iconify width={28} height={28} icon="ic:baseline-plus" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        <MenuItem onClick={() => handleClose("catalog")}>From Catalog</MenuItem>
        <MenuItem onClick={() => handleClose("products")}>From Products</MenuItem>
      </Menu>
    </>
  );
};

export default AddItem;
