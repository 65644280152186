import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { loadingBtnAction, loginAction } from "src/redux/actions/userActions";

export const registerUser = async (dispatch, payload, setStep) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/register", payload);

    if (res) {
      const userData = res.data.data.user;
      const token = res.data.data.token;
      dispatch(
        loginAction(
          token,
          userData.id,
          userData.name,
          userData.email,
          userData.image,
          userData.user_type
        )
      );
      setStep("subscribe");
      dispatch(loadingBtnAction(false));
      localStorage.setItem("token", token);
      localStorage.setItem("registered", true);
      localStorage.removeItem("userFormData");
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const googleLogin = async (dispatch, navigate, data) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/login/google", data);

    if (res) {
      const userData = res.data.data.user;
      const token = res.data.data.token;
      const image = userData?.image ?? "";

      dispatch(
        loginAction(
          token,
          userData.id,
          userData.name,
          userData.email,
          image,
          userData.UserType
        )
      );
      navigate("/dashboard/app");
      dispatch(loadingBtnAction(false));
      localStorage.setItem("token", token);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    if (e.response.status === 402 && e?.response?.data?.data?.token) {
      navigate("/signup/subscribe");
      localStorage.setItem("token", e?.response?.data?.data?.token);
      toastify("warning", "Please subscribe before logging in");
    } else {
      toastify("error", e.response.data.message);
    }
  }
};
