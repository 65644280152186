import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addCoupon, deleteCoupon } from "./requests";
import { LoadingButton } from "@mui/lab";

const Coupons = () => {
  const dispatch = useDispatch();
  const [newCoupon, setNewCoupon] = useState({ name: "", discount: "" });
  const coupons = useSelector((state) => state.payment.coupons);
  const isLoading = useSelector((state) => state.user.loadingButton2);

  const handleAddCoupon = () => {
    if (!newCoupon.name || !newCoupon.discount) return;
    const payload = {
      name: newCoupon.name,
      percent_off: newCoupon.discount,
    };
    addCoupon(dispatch, payload);
    setNewCoupon({ name: "", discount: "" });
  };

  const handleDeleteCoupon = (code) => {
    deleteCoupon(dispatch, code);
  };

  return (
    <Box sx={{ my: 8 }}>
      <h2>Manage Coupons</h2>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          label="Coupon Name"
          variant="outlined"
          size="small"
          value={newCoupon.name}
          onChange={(e) => setNewCoupon({ ...newCoupon, name: e.target.value })}
        />

        <TextField
          label="Discount(%)"
          variant="outlined"
          size="small"
          type="number"
          value={newCoupon.discount}
          onChange={(e) =>
            setNewCoupon({ ...newCoupon, discount: e.target.value })
          }
        />

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleAddCoupon}
          loading={isLoading}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
        >
          Add
        </LoadingButton>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons?.map((coupon) => (
              <TableRow key={coupon.id}>
                <TableCell>{coupon.promo_code_id}</TableCell>
                <TableCell>{coupon.percent_off}%</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteCoupon(coupon.promo_code_id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Coupons;
