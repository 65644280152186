import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AddMeetingNotes from "./AddMeetingNotes";
import Iconify from "src/components/iconify/Iconify";
import UpdateMeetingNote from "./UpdateMeetingNotes";
import DeleteMeetingNotes from "./DeleteMeetingNotes";
import TableContainer from "@mui/material/TableContainer";
import { useDispatch } from "react-redux";
import { Markup } from "interweave";
import { getNotes, onDeleteNote } from "./request";
import { setModalState } from "src/redux/actions/modalActions";
import { Button, IconButton, Stack, Typography } from "@mui/material";

const MeetingNotes = ({ userID }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    getNotes(userID, setNotes);
  }, [userID]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        my={2}
      >
        <Typography variant="h4">Meeting Notes</Typography>

        <Button
          variant={"contained"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() =>
            dispatch(
              setModalState(
                <AddMeetingNotes setNotes={setNotes} userID={userID} />
              )
            )
          }
        >
          Add Note
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Notes</TableCell>

              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes?.length ? (
              <>
                {" "}
                {notes?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">
                      <Markup content={row.notes} />
                    </TableCell>

                    <TableCell align="left">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          dispatch(
                            setModalState(
                              <UpdateMeetingNote
                                data={row}
                                setNotes={setNotes}
                              />
                            )
                          )
                        }
                      >
                        <Iconify icon={"eva:edit-fill"} />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() =>
                          dispatch(
                            setModalState(
                              <DeleteMeetingNotes
                                deleteNote={() =>
                                  onDeleteNote(
                                    dispatch,
                                    row.id,
                                    setNotes,
                                    userID
                                  )
                                }
                              />
                            )
                          )
                        }
                      >
                        <Iconify icon="eva:trash-2-outline" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="h6" paragraph>
                    There are no meeting notes.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MeetingNotes;
