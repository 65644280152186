import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/home";
import Apps from "./pages/apps";
import Cart from "./pages/cart";
import Scopes from "./pages/scopes";
import Page404 from "./pages/Page404";
import Settings from "./pages/settings";
import Orders from "./pages/orders/Orders";
import SimpleLayout from "./layouts/simple";
import UserPage from "./pages/users/UserPage";
import Profile from "./pages/profile/Profile";
import DashboardLayout from "./layouts/dashboard";
import LoginPage from "./pages/auth/login/LoginPage";
import SignupPage from "./pages/auth/register/Signup";
import CatalogPage from "./pages/catalog/CatalogPage";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";
import ConsentScreen from "./pages/oAuth/ConsentScreen";
import TermsOfService from "./pages/home/TermsOfService";
import ProductsPage from "./pages/products/ProductsPage";
import DashboardAppPage from "./pages/dashboard/DashboardAppPage";
import ProductPage from "./pages/catalog/detailPages/ProductPage";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import ShipstationCredentials from "./pages/shipstation/ShipstationCredentials";
import SubscriptionManagement from "./pages/subscription-management";
import Trainings from "./pages/trainings";
import Tutorials from "./pages/tutorials";
import TrainingsDetail from "./pages/trainings/TrainingsDetail";
import TutorialDetail from "./pages/tutorials/TutorialDetail";
import TestimonialPage from "./pages/auth/testimonial";
import CatalogImages from "./pages/home/sections/CatalogImages";
import Stores from "./pages/store/Stores";

export default function Router() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
      index: true,
    },

    {
      path: "/dashboard",
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "shipstation", element: <ShipstationCredentials /> },
        { path: "user", element: <UserPage /> },
        { path: "products/:orderID?", element: <ProductsPage /> },
        { path: "product/:id/:orderID?", element: <ProductPage /> },
        {
          path: "catalog/:orderID?",
          element: <CatalogPage />,
        },

        { path: "stores", element: <Stores /> },
        { path: "settings", element: <Settings /> },
        { path: "profile", element: <Profile /> },
        { path: "orders", element: <Orders /> },
        { path: "checkout", element: <Cart /> },
        { path: "scopes", element: <Scopes /> },
        { path: "apps", element: <Apps /> },

        { path: "trainings", element: <Trainings /> },
        { path: "trainings/:id", element: <TrainingsDetail /> },

        { path: "tutorials", element: <Tutorials /> },
        { path: "tutorials/:id", element: <TutorialDetail /> },

        {
          path: "subscription-management",
          element: <SubscriptionManagement />,
        },
      ],
    },

    {
      path: "login",
      element: <LoginPage />,
    },

    {
      path: "apply",
      element: <TestimonialPage />,
    },

    {
      path: "catalog",
      element: <CatalogImages />,
    },

    {
      path: "signup/:mode?",
      element: <SignupPage />,
    },

    { path: "reset-password", element: <ForgotPassword /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },

    {
      path: "termsofservice",
      element: <TermsOfService />,
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
    },

    //oAuth Routes
    {
      path: "/app/oauth2/auth",
      element: <ConsentScreen />,
    },
  ]);

  return routes;
}
