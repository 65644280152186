import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  Cancel,
  CheckCircle,
  Visibility,
  VisibilityOff,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import * as yup from "yup";
import { registerUser } from "../requests";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Must be at least 8 characters.")
    .matches(/[a-z]/, "Must include lowercase letters.")
    .matches(/[A-Z]/, "Must include uppercase letters.")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must include at least one special character."
    )
    .matches(/^\S*$/, "Cannot include spaces."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Entries must match."),
});

const passwordValidations = [
  { check: (pwd) => pwd.length >= 8, text: "Must be at least 8 characters." },
  {
    check: (pwd) => /[A-Z]/.test(pwd) && /[a-z]/.test(pwd),
    text: "Must include uppercase and lowercase letters.",
  },
  {
    check: (pwd) => /[!@#$%^&*(),.?":{}|<>]/.test(pwd),
    text: "Must include at least one special character.",
  },
  { check: (pwd) => !/\s/.test(pwd), text: "Cannot include spaces." },
  {
    check: (pwd, confirmPwd) => pwd && confirmPwd && pwd === confirmPwd,
    text: "Entries must match.",
  },
];

const PasswordForm = ({ setStep, userData }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const isLoading = useSelector((state) => state.user.loadingButton);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const password = watch("password", "");
  const confirmPassword = watch("confirmPassword", "");

  useEffect(() => {
    const inputs = document.querySelectorAll('input[type="password"]');
    inputs.forEach((input) => {
      input.setAttribute("readonly", "true");
      setTimeout(() => {
        input.removeAttribute("readonly");
      }, 100);
    });
  }, []);

  const getIcon = useMemo(
    () => (condition) =>
      password === "" ? (
        <RadioButtonUnchecked sx={{ color: "gray" }} />
      ) : condition ? (
        <CheckCircle sx={{ color: "#2cd284" }} />
      ) : (
        <Cancel sx={{ color: "#ef4444" }} />
      ),
    [password]
  );

  const ValidationItem = ({ isValid, text }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {getIcon(isValid)}
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "normal",
          wordBreak: "break-word",
          lineHeight: 1.4,
          textAlign: "left",
        }}
      >
        {text}
      </Typography>
    </Box>
  );

  const onSubmit = (data) => {
    const payload = {
      name: userData?.firstName + " " + userData?.lastName,
      email: userData?.email,
      password: data?.password,
      password_confirmation: data?.confirmPassword,
    };

    registerUser(dispatch, payload, setStep);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={3}>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "center", color: "text.secondary", my: 2 }}
        >
          Please create a secure password including the following criteria
          below.
        </Typography>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="custom-password"
              type={showPassword ? "text" : "password"}
              label="New Password"
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      tabIndex={-1}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type={"password"}
              label="Confirm New Password"
              fullWidth
              margin="normal"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              autoComplete="off"
            />
          )}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {passwordValidations.map(({ check, text }, index) => (
            <ValidationItem
              key={index}
              isValid={check(password, confirmPassword)}
              text={text}
            />
          ))}
        </Box>

        <Button type="submit" fullWidth variant="contained" size="large">
          {isLoading ? "Loading..." : "CREATE PASSWORD"}
        </Button>

        <Button
          fullWidth
          variant="text"
          sx={{ fontSize: "18px" }}
          startIcon={<ArrowBack />}
          size="large"
          type="button"
          onClick={() => setStep("user")}
        >
          Back
        </Button>
      </Stack>
    </form>
  );
};

export default PasswordForm;
