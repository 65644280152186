import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import Nav from "./nav";
import Header from "./header";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  maxHeight: "100vh",
  overflow: "hidden",
}));

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "50vh",
  maxHeight: "84vh",
  paddingTop: 24,
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  // Webkit scrollbar styles
  "::-webkit-scrollbar": {
    width: "12px",
    height: "4px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "grey",
    borderRadius: "20px",
  },

  // Responsive styles
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

const FooterContainer = styled(Paper)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  zIndex: "auto",
}));

export default function DashboardLayout() {
  const myDivRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title> Inner Circle Prints </title>
      </Helmet>

      <Header onOpenNav={() => setOpen(true)} />

      <StyledRoot>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
        <Main ref={myDivRef}>
          <Outlet />
        </Main>
      </StyledRoot>
      {/* {userType === "Seller" && (
        <FooterContainer component="footer" square variant="outlined">
          <FooterContent />
        </FooterContainer>
      )} */}
    </>
  );
}
