import React from "react";
import Iconify from "src/components/iconify/Iconify";
import StyledButton from "src/components/button/StyledButton";
import LinkEtsyProduct from "src/sections/modals/products/LinkEtsyProduct";
import { Stack } from "@mui/material";
import { unLinkProduct } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import LinkEtsySKU from "src/sections/modals/products/LinkEtsySKU";

const LinkToEtsy = () => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const listingID = catalogDetails?.listing_id;

  const linkEtsyListing = () => {
    if (listingID === null) {
      dispatch(
        setModalState(
          <LinkEtsyProduct id={catalogDetails?.id} page={1} rowsPerPage={25} />
        )
      );
    } else {
      unLinkProduct(dispatch, catalogDetails?.id, "etsy", 1, 25);
    }
  };

  const linkEtsySKU = () => {
    dispatch(
      setModalState(
        <LinkEtsySKU product={catalogDetails} page={1} rowsPerPage={25} />,
        true
      )
    );
  };

  return (
    <Stack direction={"row"} spacing={3} justifyContent={"flex-end"}>
      <StyledButton
        title={listingID === null ? "Link To Etsy" : "Unlink Etsy product"}
        sx={{
          bgcolor: "#f2521b",
          "&:hover": {
            bgcolor: "#F2441B",
          },
        }}
        startIcon={<Iconify icon="la:etsy" />}
        onClick={linkEtsyListing}
      />
      {listingID && (
        <StyledButton
          title={"Link Etsy SKU"}
          startIcon={<Iconify icon="la:etsy" />}
          onClick={linkEtsySKU}
        />
      )}
    </Stack>
  );
};

export default LinkToEtsy;
