import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Fade, useMediaQuery } from "@mui/material";
import { setModalState } from "src/redux/actions/modalActions";

const defaultStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "60%",
  minWidth: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "15px",
  maxHeight: "calc(100vh - 225px)",
  boxShadow: 4,
  padding: 4,
  overflowY: "scroll",
  "&::-webkit-scrollbar": { width: "0.4em" },
  "&::-webkit-scrollbar-track": { boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)" },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.5)",
    outline: "1px solid slategrey",
  },
};

const fullScreenStyle = {
  ...defaultStyle,
  width: "80%",
  height: "90%",
  // paddingLeft: 0,
  maxWidth: "none",
  maxHeight: "none",
};

const mobileDrawerStyle = {
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  borderRadius: "15px 15px 0 0",
  boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
  p: 3,
  overflowY: "auto",
  transition: "transform 0.3s ease-out",
};

export default function ModalProvider() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const ModalOutlet = useSelector((state) => state.modal.openModal);
  const isFullScreen = useSelector((state) => state.modal.isFullScreen);
  const customStyles = useSelector((state) => state.modal.customStyles) || {};

  const isOpen = !!ModalOutlet;
  const modalStyle = isMobile
    ? { ...mobileDrawerStyle, ...customStyles }
    : isFullScreen
    ? { ...fullScreenStyle, ...customStyles }
    : { ...defaultStyle, ...customStyles };

  return (
    isOpen && (
      <Modal
        open={isOpen}
        onClose={() => dispatch(setModalState(undefined))}
        style={{ zIndex: 1200 }}
        className="modal"
        closeAfterTransition
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Fade in={isOpen}>
          <Box sx={modalStyle}>{ModalOutlet}</Box>
        </Fade>
      </Modal>
    )
  );
}
