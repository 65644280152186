import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import Iconify from "src/components/iconify/Iconify";
import MyImage from "src/components/lazy-load-image/MyImage";
import AddCatalogForm from "src/sections/modals/catalog/addCatalog";
import DeleteCatalog from "src/sections/modals/catalog/deleteCatalog";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { fCurrency } from "../../../utils/formatNumber";
import { deleteCatalog } from "src/pages/catalog/request";
import { Box, Card, Typography, Stack, Badge } from "@mui/material";
import { setModalState } from "src/redux/actions/modalActions";
import { setCatalogDetails } from "src/redux/actions/orderActions";

const StyledLink = styled(Link)({
  textDecoration: "none",
});

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, page, rowsPerPage }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const {
    id,
    title,
    images,
    price,
    shipping_price,
    listing_id,
    amazon_sku,
    breadCrumb,
  } = product;
  const { orderID } = useParams();

  const link = orderID
    ? `/dashboard/product/${id}/${orderID}`
    : `/dashboard/product/${id}`;

  return (
    <Card onClick={() => dispatch(setCatalogDetails(product))}>
      <StyledLink to={link}>
        <Box sx={{ pt: "100%", position: "relative" }}>
          {product?.print_provider ? (
            <Badge
              badgeContent={product?.print_provider?.title}
              color="primary"
              sx={{
                zIndex: 9,
                top: 16,
                right: 18,
                position: "absolute",
                textTransform: "uppercase",
                padding: 20,
                marginRight: 7,
              }}
            />
          ) : (
            ""
          )}
          <MyImage
            image={images[0]}
            sx={{
              top: 0,
              position: "absolute",
              width: "100% ",
              height: "100%",
            }}
          />
        </Box>
      </StyledLink>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>

        <Stack
          direction="row"
          sx={{ color: "#02B2FE" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">
            <div>Price {fCurrency(price)}</div>
            {shipping_price && <div>Shipping {fCurrency(shipping_price)}</div>}
          </Typography>
          {userType === "Super Admin" ? (
            <div>
              <Iconify
                icon="eva:edit-fill"
                sx={{ mr: 1, cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    setModalState(<AddCatalogForm product={product} />, true)
                  )
                }
              ></Iconify>

              <Iconify
                icon={"eva:trash-2-outline"}
                sx={{ cursor: "pointer" }}
                color={"red"}
                onClick={() =>
                  dispatch(
                    setModalState(
                      <DeleteCatalog
                        deleteCatalog={() => deleteCatalog(dispatch, id)}
                      />
                    )
                  )
                }
              />
            </div>
          ) : breadCrumb === "products" ? (
            <Dropdown
              id={id}
              listingID={listing_id}
              amazonSKU={amazon_sku}
              product={product}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            ""
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
