import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { loadingAction, loadingBtn2Action, loadingBtnAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import {
  setTrainingsData,
  setTrainingSections,
} from "src/redux/actions/educationActions";

export const getTrainingsData = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/training");
    if (res) {
      dispatch(loadingAction(false));
      dispatch(setTrainingsData(res.data.data));
    }
  } catch (e) {
    dispatch(loadingAction(false));
    if (e.response.status === 404) {
      dispatch(setTrainingsData([]));
    } else {
      toastify("error", e.response.data.message);
    }
  }
};

export const getTrainingsSections = async (dispatch) => {
  try {
    const res = await request.get("/training-sections");
    if (res) {
      dispatch(setTrainingSections(res.data.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      dispatch(setTrainingSections([]));
    } else {
      toastify("error", e.response.data.message);
    }
  }
};

export const addTrainingSection = async (dispatch, payload) => {
  dispatch(loadingBtn2Action(true));
  try {
    const res = await request.post("/training-sections", payload);

    if (res) {
      getTrainingsSections(dispatch);
      dispatch(loadingBtn2Action(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtn2Action(false));
    toastify("error", e.response.data.message);
  }
};

export const addTrainingContent = async (dispatch, formData) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/training", formData);

    if (res) {
      dispatch(setModalState(undefined));
      getTrainingsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const updateTrainingContent = async (dispatch, id, formData) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/training/${id}`, formData);

    if (res) {
      dispatch(setModalState(undefined));
      getTrainingsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const deleteTrainingContent = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/training/${id}`);

    if (res) {
      dispatch(setModalState(undefined));
      getTrainingsData(dispatch);
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};
