import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import Iconify from "src/components/iconify";
import AddTrainings from "src/sections/modals/trainings/AddTrainings";
import { useDispatch, useSelector } from "react-redux";
import { StyledLink } from "../auth/login/Login.styles";
import { setModalState } from "src/redux/actions/modalActions";
import { deleteTrainingContent, getTrainingsData } from "./requests";
import { setTrainingsDetail } from "src/redux/actions/educationActions";
import DeleteContent from "src/sections/modals/trainings/DeleteContent";
import TrialEndCard from "./TrialEndCard";
import MyImage from "src/components/lazy-load-image/MyImage";

const Trainings = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const trainingsData = useSelector((state) => state.education.trainingsData);
  const subscription = useSelector((state) => state.user.subscription);
  const sections = Object.keys(trainingsData || {});
  const [selectedSection, setSelectedSection] = useState("");

  useEffect(() => {
    getTrainingsData(dispatch);
  }, [dispatch, userType]);

  useEffect(() => {
    if (!selectedSection && sections.length > 0) {
      setSelectedSection(sections[0]);
    }
  }, [sections]);

  const handleTabChange = (event, newValue) => {
    setSelectedSection(newValue);
  };

  return (
    <Container maxWidth="2xl">
      {subscription?.is_trial === "yes" ? (
        <TrialEndCard />
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4}
          >
            <Typography variant="h4">Trainings</Typography>
            {userType === "Super Admin" && (
              <Button
                variant={"contained"}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => dispatch(setModalState(<AddTrainings />))}
              >
                Add Content
              </Button>
            )}
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
            <Tabs
              value={selectedSection || sections[0] || ""}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {sections?.map((section) => (
                <Tab key={section} label={section} value={section} />
              ))}
            </Tabs>
          </Box>

          {trainingsData[selectedSection]?.length ? (
            <Grid container spacing={3}>
              {trainingsData[selectedSection].map((item) => (
                <Grid key={item.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Card onClick={() => dispatch(setTrainingsDetail(item))}>
                    <StyledLink to={`/dashboard/trainings/${item?.id}`}>
                      <Box sx={{ pt: "100%", position: "relative" }}>
                        <MyImage
                          image={[item?.image]}
                          sx={{
                            top: 0,
                            position: "absolute",
                            width: "100% ",
                            height: "100%",
                          }}
                        />
                      </Box>
                    </StyledLink>

                    <Stack direction={"row"} spacing={1} sx={{ p: 3 }}>
                      <Typography variant="h6" noWrap>
                        {item?.title}
                      </Typography>

                      {userType === "Super Admin" && (
                        <>
                          <IconButton
                            onClick={() =>
                              dispatch(
                                setModalState(
                                  <AddTrainings trainingData={item} />
                                )
                              )
                            }
                          >
                            <Iconify
                              icon="eva:edit-fill"
                              color="primary.main"
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              dispatch(
                                setModalState(
                                  <DeleteContent
                                    onDelete={() =>
                                      deleteTrainingContent(dispatch, item?.id)
                                    }
                                  />
                                )
                              )
                            }
                          >
                            <Iconify icon="eva:trash-2-outline" color={"red"} />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh" }}
            >
              <Grid item xs={3}>
                There is no content added yet...
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Container>
  );
};

export default Trainings;
