import {
  Button,
  CardMedia,
  CardContent,
  Card,
  Box,
  Tooltip,
  tooltipClasses,
  Paper,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "10px",
}));

export const StyledPaperContainer = styled(Paper)(({ theme }) => ({
  border: `4px dashed ${theme.palette.primary.main}`,
  backgroundColor: "#fff",
  borderRadius: "8px",
  flex: 1,
  height: "100px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: { xs: "100%", sm: "45%" },
}));

export const StyledRoot = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "30px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: "24px",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  // width: '60%',
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  // padding: "20px 120px",
  // textAlign: "left",
  // [theme.breakpoints.down("lg")]: {
  //   padding: "24px",
  // },

  // [theme.breakpoints.down("sm")]: {
  //   "& .MuiBox-root": {
  //     marginLeft: "0px",
  //   },
  // },

  "& .child": {
    marginTop: "28px",
    marginBottom: "28px",
  },

  ".canva-input": {
    width: "80%",

    fieldset: {
      border: "transparent",
    },
    input: {
      padding: 0,
    },
    label: {
      top: -8,
    },
  },
}));

export const StyledDesignButton = styled(Button)(({ theme }) => ({
  width: "350px",
  borderRadius: "40px",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  maxWidth: "350px",
  borderRadius: "40px",
}));

export const StyledCanvaURl = styled("div")(({ theme }) => ({
  background: "#fff",
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  padding: "10px 0px",
  borderRadius: "10px",
  margin: "5px 0px",
  maxWidth: "350px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.primary.main,
  },
}));

export const StyledImageUpload = styled(Button)(({ theme }) => ({
  background: "#fff",
  border: `2px dashed ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  width: "100%",
  padding: "10px 0px",
  borderRadius: "10px",
  margin: "16px 0px",
  maxWidth: "350px",
}));

export const StyledDesignBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // width: '50%',
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // padding:'20px',
  },
}));

export const EditImageContainer = styled(Button)(({ theme }) => ({
  height: "44px",
  minWidth: "44px",
  borderRadius: "50%",
  backgroundColor: theme.palette.grey[400],
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  position: "absolute",
  top: 10,
  right: 10,
  textAlign: "-webkit-right",
  zIndex: 999,

  "&:hover": {
    backgroundColor: theme.palette.grey[500],
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#02B2FE",
    boxShadow: theme.shadows[8],
    fontSize: 14,
  },
}));

export const StyledEditBox = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.grey[300],
  fontWeight: 700,
  borderRadius: "40px",
  textAlign: "center",
  fontSize: "0.9375rem",
  minWidth: "64px",
  maxWidth: "340px",
  padding: "8px 22px",
  lineHeight: "1.7142857142857142",
}));
