const defaultState = {
  openModal: undefined,
  isFullScreen: false,
  customStyles: {},
};

const modalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        openModal: action.payload.openModal,
        isFullScreen: action.payload.isFullScreen,
        customStyles: action.payload.customStyles,
      };

    default:
      return state;
  }
};

export default modalReducer;
