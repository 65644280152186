import React from "react";
import { Box, CardMedia, Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Markup } from "interweave";
import { ArrowBackIos } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

const TrainingsDetail = () => {
  const trainingDetail = useSelector((state) => state.education.trainingDetail);

  return (
    <Container maxWidth="xl">
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} spacing={1} alignItems={"center"} mb={2}>
          <NavLink to={"/dashboard/trainings"}>
            <ArrowBackIos />
          </NavLink>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            {trainingDetail?.title}
          </Typography>
        </Stack>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
          }}
        >
          <CardMedia
            component="iframe"
            src={trainingDetail?.link}
            sx={{
              width: "100%",
              height: "400px",
              borderRadius: "8px",
            }}
            frameBorder="0"
            allowFullScreen
          />
        </Box>

        <Typography variant="body1" sx={{ color: "text.secondary", my: 4 }}>
          <Markup content={trainingDetail?.description} />
        </Typography>
      </Box>
    </Container>
  );
};

export default TrainingsDetail;
