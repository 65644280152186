import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";
import {
  capitalizeFirstLetter,
  isEmptyObject,
} from "src/utils/commonFunctions";
import { Image } from "@mui/icons-material";
import DynamicMockupEditor from "src/components/mockup-editor/DynamicMockupEditor";
import { catalogUUIDs } from "src/components/mockup-editor/utils";

const UploadFile = ({
  uploadedFile,
  catalogDetails,
  disableUpload,
  deleteFile,
  type,
}) => {
  const dispatch = useDispatch();
  const fileIsUploaded =
    uploadedFile?.src && !isEmptyObject(uploadedFile?.src) ? true : false;

  const mockupUUID =
    type === "notecard"
      ? "fea2a63e-445d-4f0c-8377-142794245a0c"
      : catalogUUIDs?.find(
          (item) => item.catalogID === parseInt(catalogDetails?.id)
        )?.mockup_uuid;

  const imageTitle =
    type === "graphic"
      ? catalogDetails?.no_of_graphics === 1
        ? "Graphic Design & Create Thumbnail"
        : catalogDetails?.no_of_graphics === 2
        ? "Pendant Design  & Create Thumbnail"
        : ""
      : "Notecard Graphic";

      const place =
      type === "graphic"
        ? catalogDetails?.no_of_graphics === 1
          ? "graphic"
          : catalogDetails?.no_of_graphics === 2
          ? "graphic"
          : ""
        : "notecard";

  return fileIsUploaded ? (
    <DeleteImageViewer
      file={uploadedFile?.src}
      fileName={uploadedFile?.fileName}
      deleteFile={deleteFile}
      popup
    />
  ) : (
    <Button
      sx={{
        width: "100%",
        height: "100%",
        color: "text.secondary",
        "&:hover": {
          backgroundColor: "#f3f3f3",
        },
      }}
      disabled={disableUpload}
      onClick={() =>
        dispatch(
          setModalState(
            <DynamicMockupEditor
              type={type}
              mockupUUID={mockupUUID}
              place={place}
            />,
            true,
            { paddingLeft: 0 }
          )
        )
      }
      startIcon={<Image />}
    >
      Upload {capitalizeFirstLetter(imageTitle)}{" "}
    </Button>
  );
};

export default UploadFile;
