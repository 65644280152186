import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import supportIcon from "../../assets/icons/support_icon.png";
import Iconify from "src/components/iconify/Iconify";
import { getPosts } from "./requests";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { Markup } from "interweave";
import { StyledStack } from "./utils";
import { Edit } from "@mui/icons-material";
import { fToNow } from "src/utils/formatTime";
import DeletePost from "src/sections/modals/updates/DeletePost";
import CreateUpdate from "src/sections/modals/updates/CreateUpdate";
import ImageViewPopup from "src/components/image-viewer/ImageViewPopup";
import { Avatar, Box, Button, IconButton, Paper, Stack } from "@mui/material";

const Update = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const updates = useSelector((state) => state.education.updates);

  useEffect(() => {
    getPosts(dispatch);
  }, [dispatch]);

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `FileName.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Paper
      sx={{
        borderRadius: "15px",
        my: "40px",
      }}
      maxWidth={false}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        my={3}
      >
        <Typography variant="h4">Updates</Typography>
        {userType === "Super Admin" && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => dispatch(setModalState(<CreateUpdate />, true))}
          >
            Add Update
          </Button>
        )}
      </Stack>

      {updates?.length ? (
        updates?.map((item, index) => (
          <Card variant="outlined" key={index} sx={{ mb: 3, zIndex: "auto" }}>
            <CardContent>
              <Stack
                direction={"row"}
                spacing={3}
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Box>
                    <Avatar alt={"admin"} src={supportIcon} />
                  </Box>
                  <Typography variant="subtitle1">ICP Support</Typography>
                  <Box
                    sx={{
                      backgroundColor: "#DFE3E8",
                      borderRadius: "16px",
                      padding: "0px 8px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="caption">
                      {fToNow(item?.created_at)}
                    </Typography>
                  </Box>
                </Stack>
                {userType === "Super Admin" ? (
                  <Stack direction={"row"} alignItems={"center"} spacing={3}>
                    <Box>
                      <Edit
                        sx={{ cursor: "pointer", color: "#47A5E6" }}
                        onClick={() => {
                          dispatch(
                            setModalState(<CreateUpdate item={item} />, true)
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      <Iconify
                        icon={"eva:trash-2-outline"}
                        sx={{ cursor: "pointer" }}
                        color={"red"}
                        onClick={() =>
                          dispatch(setModalState(<DeletePost item={item} />))
                        }
                      />
                    </Box>
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>

              {item?.content ? (
                <Box sx={{ mb: 3 }}>
                  <Markup content={item?.content} />{" "}
                </Box>
              ) : (
                ""
              )}

              {item?.links ? (
                <Box sx={{ mb: 4 }}>
                  <a href={item?.links}>{item?.links}</a>
                </Box>
              ) : (
                ""
              )}

              <Stack direction={"row"} spacing={3} flexWrap={"wrap"}>
                {item?.images ? (
                  <Box sx={{ mb: 4 }}>
                    <ImageViewPopup
                      imageSRC={[item?.images]}
                      fileName={"post"}
                      sx={{ width: "100%", height: 300 }}
                    />
                  </Box>
                ) : (
                  ""
                )}

                {item?.videos ? (
                  <Box sx={{ mb: 4 }}>
                    <CardMedia
                      component="video"
                      controls
                      height="300"
                      src={item?.videos}
                      sx={{ width: "300px" }}
                      title="Video"
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Stack>
              {item?.document ? (
                <>
                  <Box sx={{ my: 4 }}></Box>
                  <StyledStack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ maxWidth: "300px" }}
                  >
                    <Stack direction={"row"} spacing={3}>
                      <Iconify
                        icon="teenyicons:text-document-solid"
                        color="primary.main"
                      />
                      <Typography variant="body2">Document</Typography>
                    </Stack>
                    <IconButton onClick={() => downloadFile(item?.document)}>
                      <Iconify color="primary.main" icon="bi:cloud-download" />
                    </IconButton>
                  </StyledStack>
                </>
              ) : (
                ""
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <Paper elevation={3} sx={{ width: "100%", p: 4, textAlign: "center" }}>
          <Typography variant="h6" paragraph>
            No Updates Available
          </Typography>
          <Typography variant="caption" paragraph>
            It looks like there are no new updates at the moment. Please check
            back later for the latest information.
          </Typography>
        </Paper>
      )}
    </Paper>
  );
};

export default Update;
