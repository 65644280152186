import React from "react";
import { styled } from "@mui/system";

const Image = styled("img")(({ index }) => ({
  position: "absolute",
  width: "80px",
  height: "80px",
  left: index * 20,
  top: index * 20,
  objectFit: "inherit",
  cursor:"pointer"
}));

const OverlappingImages = ({ image, index, onClick, style }) => {
  return (
    <Image
      key={index}
      src={image}
      alt={`Image ${index}`}
      style={{ ...style }}
      onClick={onClick}
      index={index}
    />
  );
};

export default OverlappingImages;
