import { alpha } from "@mui/material/styles";

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const GREEN = {
  main: "#4DC369",
  contrastText: "#fff",
};

const Yellow = {
  main: "#F2F200",
  contrastText: "#000",
};

const CARD_ONE = {
  main: "#bde6f8",
  contrastText: "#0AA4E6",
};

const CARD_TWO = {
  main: "#EDCACA",
  contrastText: "#D95E5A",
};

const CARD_THREE = {
  main: "#F4DFC9",
  contrastText: "#EF9A48",
};

const CARD_FOUR = {
  main: "#C8E9D3",
  contrastText: "#4DC369",
};

const ICON_ONE = {
  main: "#7AD6FE",
  contrastText: "#0AA4E6",
};

const ICON_TWO = {
  main: "#E8AAA9",
  contrastText: "#D95E5A",
};

const ICON_THREE = {
  main: "#F4CEA7",
  contrastText: "#EF9A48",
};

const ICON_FOUR = {
  main: "#A9DFB8",
  contrastText: "#4DC369",
};

const WHITE = {
  lighter: "#D1E9FC",
  light: "#76B0F1",
  main: "#FFFFFF",
  dark: "#103996",
  darker: "#061B64",
  contrastText: "#fff",
};

const PRIMARY = {
  lighter: "#D1E9FC",
  light: "#76B0F1",
  main: "#02B2FE",
  dark: "#0097d9",
  darker: "#061B64",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#000000",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  white: WHITE,
  green: GREEN,
  yellow: Yellow,
  card_one: CARD_ONE,
  card_two: CARD_TWO,
  card_three: CARD_THREE,
  card_four: CARD_FOUR,
  icon_one: ICON_ONE,
  icon_two: ICON_TWO,
  icon_three: ICON_THREE,
  icon_four: ICON_FOUR,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: "#fff",
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
