import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  addTutorialsContent,
  updateTutorialsContent,
} from "src/pages/tutorials/requests";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const fullToolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ script: "sub" }, { script: "super" }],
  ["link", "image", "video"],
  ["clean"],
];

const AddTutorials = ({ tutorialsData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);
  const [editorValue, setEditorValue] = useState(
    tutorialsData ? tutorialsData?.description : ""
  );

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    link: yup.string().url("Enter a valid URL").required("Link is required"),
    image: yup.mixed().when([], {
      is: () => !tutorialsData,
      then: (schema) => schema.required("Image is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: tutorialsData?.title ?? "",
      link: tutorialsData?.link ?? "",
    },
  });

  const [preview, setPreview] = useState(tutorialsData?.image ?? null);

  const onSubmit = (data) => {
    reset();
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("description", editorValue);
    formData.append("link", data?.link);

    if (!tutorialsData || data?.image) {
      formData.append("image", data?.image);
    }

    if (tutorialsData) {
      updateTutorialsContent(dispatch, tutorialsData?.id, formData);
    } else {
      addTutorialsContent(dispatch, formData);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue("image", file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" mb={2}>
        Add Tutorial Content
      </Typography>

      <Stack direction={"row"} spacing={3} alignItems={"center"} mb={3}>
        <Box mt={2}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="upload-image"
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Thumbnail
            </Button>
          </label>
        </Box>
        {!preview && errors.image && (
          <Typography color="error">Preview {errors.image.message}</Typography>
        )}

        {preview && (
          <Box mt={2}>
            <img
              src={preview}
              alt="Preview"
              style={{ width: 80, borderRadius: 8, marginTop: 8 }}
            />
          </Box>
        )}
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Add Title"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title?.message}
          required
          margin="normal"
        />

        <ReactQuill
          theme="snow"
          placeholder="Add Description"
          modules={{ toolbar: fullToolbarOptions }}
          value={editorValue}
          onChange={setEditorValue}
        />

        <TextField
          label="Tutorials Video Link"
          fullWidth
          {...register("link")}
          error={!!errors.link}
          helperText={errors.link?.message}
          required
          margin="normal"
        />

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          loading={isLoading}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
        >
          Submit
        </LoadingButton>
      </form>
    </Box>
  );
};

export default AddTutorials;
