import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  Button,
  CircularProgress,
  Box,
  Chip,
} from "@mui/material";
import Iconify from "../iconify";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";

const getFeatures = (desc) =>
  desc
    ?.split(".")
    .map((s) => s.trim())
    .filter(Boolean)
    .slice(0, 5);

const SubscriptionCard = ({
  item,
  status,
  onDelete,
  discount,
  onCancel,
  resubscribe,
}) => {
  let features = getFeatures(item?.description);
  const isLoading = useSelector((state) => state.user.loadingButton);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" gutterBottom>
            {item?.name}
          </Typography>
          {onDelete && (
            <IconButton color="error" onClick={onDelete}>
              <Iconify icon={"eva:trash-2-outline"} />
            </IconButton>
          )}
        </Stack>

        {discount < item?.price ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="caption"
                sx={{ textDecoration: "line-through", color: "gray", mr: 1 }}
              >
                ${item?.price ?? 0}
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "primary.main" }}
              >
                ${parseFloat(discount).toFixed(2) ?? 0}
              </Typography>
              <Typography variant="h6" color="primary">
                {item?.interval ? "/" + item?.interval : ""}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="h6" color="primary" gutterBottom>
            ${item?.price ?? 0} {item?.interval ? "/" + item?.interval : ""}
          </Typography>
        )}

        <List>
          {features?.map((feature, index) => (
            <ListItem
              key={index}
              sx={{
                px: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{feature}</Typography>
            </ListItem>
          ))}
        </List>
        {status === "success" ? (
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            color="inherit"
            fullWidth
            onClick={onCancel}
          >
            Cancel Subscription 
          </Button>
        ) : status === "canceled" ? (
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={resubscribe}
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Resubscribe
          </LoadingButton>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;
