import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCurrentYear } from "src/utils/formatDate";

export default function FooterContent() {
  return (
    <Box sx={{ flexGrow: 1 }} my={2} px={{ xs: 2, md: 4 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: "center", md: "center" }}
        textAlign={{ xs: "center", md: "left" }}
        spacing={{ xs: 2, md: 0 }}
        flexWrap="wrap"
      >
        {/* Footer Text */}
        <Stack spacing={0.5}>
          <Typography variant="subtitle2" color="text.primary">
            {getCurrentYear()} © Inner Circle Prints. All Rights Reserved.
          </Typography>
          <Typography variant="caption" color="text.secondary">
            The term 'Etsy' is a trademark of Etsy, Inc. This application uses
            the Etsy API but is not endorsed or certified by Etsy, Inc.
          </Typography>
        </Stack>

        {/* Links Section */}
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={{ xs: "center", md: "flex-start" }}
          flexWrap="wrap"
          sx={{ pr: 6 }}
        >
          <NavLink
            to="/termsofservice"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" color="primary">
              Terms of Service
            </Typography>
          </NavLink>

          <NavLink
            to="/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2" color="primary">
              Privacy Policy
            </Typography>
          </NavLink>
        </Stack>
      </Stack>
    </Box>
  );
}
