export const catalogUUIDs = [
  { catalogID: 5, mockup_uuid: "e6e8aed3-5c88-40a8-8e39-8567036c6e7c" }, // 20 oz Tumbler
  { catalogID: 55, mockup_uuid: "cb3fa033-0a14-4158-8a68-8a66a2d49d7b" }, // 40 oz Tumbler
  { catalogID: 6, mockup_uuid: "d8cb72ab-b30e-4cc5-bafe-345e3e5101da" }, // Ornament
  { catalogID: 27, mockup_uuid: "df0879a6-58a2-49ff-81c1-f45c36c01529" }, // Necklace
  { catalogID: 38, mockup_uuid: "95063263-85f8-48ef-b6a8-c3a17110ec9d" }, // Bracelet
  { catalogID: 39, mockup_uuid: "059bc5a5-292b-4db0-9451-578ff140da3b" }, // Keychain
  { catalogID: 40, mockup_uuid: "c45847c5-74f2-4341-ac56-1be2d09b7538" }, // Frosted Cup
  { catalogID: 45, mockup_uuid: "a74f1b1c-3893-4cd1-ba94-2e3cc4bc3dbd" }, // Initials
  { catalogID: 47, mockup_uuid: "57e6d9fb-4760-47f7-a1e4-bd94497d8f01" }, // Onesie
  { catalogID: 48, mockup_uuid: "30474e5f-82e9-48e2-ab73-420a18138c8f" }, // Toddler Shirt
  { catalogID: 49, mockup_uuid: "30474e5f-82e9-48e2-ab73-420a18138c8f" }, // Youth Shirt
  { catalogID: 50, mockup_uuid: "198342b6-085d-4452-be92-5c66f7ec9811" }, // Wooden Sign
  { catalogID: 51, mockup_uuid: "42d64a65-af16-4601-b964-6966cf4a1c5f" }, // Framed Print
  { catalogID: 52, mockup_uuid: "03a5fade-a484-4f7d-b78c-5c6df81b77c8" }, // Coffee Mugs
  { catalogID: 54, mockup_uuid: "4d3e98ed-a8c6-4943-b58f-c5f90b61fc50" }, // Print
  { catalogID: 111, mockup_uuid: "fea2a63e-445d-4f0c-8377-142794245a0c" }, // notecard
];

export const catalogUUIDsVariations = [
  {
    catalogID: 52,
    size: "11 oz White Mug",
    mockup_uuid: "98530eef-89ed-4eca-9bff-757f8a5de758",
  }, // 11 oz White Mug

  {
    catalogID: 52,
    size: "11 oz White & Black Mug",
    mockup_uuid: "98530eef-89ed-4eca-9bff-757f8a5de758",
  }, // 11 oz White & Black Mug

  {
    catalogID: 52,
    size: "15 oz White Mug",
    mockup_uuid: "03a5fade-a484-4f7d-b78c-5c6df81b77c8",
  }, // 15 oz White mug

  {
    catalogID: 52,
    size: "15 oz White & Black Mug",
    mockup_uuid: "03a5fade-a484-4f7d-b78c-5c6df81b77c8",
  }, // 15 oz White & Black Mug

  {
    catalogID: 51,
    size: "8x8",
    mockup_uuid: "138f57c1-c462-4180-bc7e-c1361120f1d0",
  }, // 88 Frame
  {
    catalogID: 51,
    size: "12x12",
    mockup_uuid: "42d64a65-af16-4601-b964-6966cf4a1c5f",
  }, // 12x12 Frame
  {
    catalogID: 51,
    size: "17x25",
    mockup_uuid: "5b1a2dad-fca9-4018-ac4f-69d89d7f82ae",
  }, // 17x25 Frame
  {
    catalogID: 51,
    size: "24x36",
    mockup_uuid: "32b3e523-e751-4d96-a9c4-a4f8a13b1c4f",
  }, // 24x36 Frame

  {
    catalogID: 50,
    size: "12x18",
    mockup_uuid: "0efa5a0a-1b39-42cc-be71-8ccb875bf27c",
  }, // 18x12 Wood Sign

  {
    catalogID: 50,
    size: "18x24",
    mockup_uuid: "084796e0-2f0a-40b4-a503-9cdeba03f8c1",
  }, // 18x24 Wood Sign
  {
    catalogID: 50,
    size: "20x30",
    mockup_uuid: "01c07050-5bdc-40f8-bd20-3bd410c3edb4",
  }, // 20x30 Wood Sign
  {
    catalogID: 50,
    size: "24x36",
    mockup_uuid: "198342b6-085d-4452-be92-5c66f7ec9811",
  }, // 24x36 Wood Sign

  {
    catalogID: 54,
    size: "8x8",
    mockup_uuid: "3234763a-12de-420e-9f16-b18c2e415a45",
  }, // 8x8 Print
  {
    catalogID: 54,
    size: "12x12",
    mockup_uuid: "068fcb47-67d9-4642-b17d-ce70ce974cb4",
  }, // 12x12 Print
  {
    catalogID: 54,
    size: "17x25",
    mockup_uuid: "4d3e98ed-a8c6-4943-b58f-c5f90b61fc50",
  }, // 17x25 Print
  {
    catalogID: 54,
    size: "24x36",
    mockup_uuid: "85bfb032-7462-49ca-a167-fc0465660cea",
  }, // 24x36 Print
];
