import { motion } from "framer-motion";
import etsyIcon from "../../../assets/etsy.png";
import { ArrowForward } from "@mui/icons-material";
import amazonIcon from "../../../assets/amazon.png";
import { Button, Typography, Container, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import VoomlyEmbed from "src/components/voomly/VoomlyEmbed";

export default function Hero() {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", py: 4 }}>
      <Stack direction="column" justifyContent="center" alignItems={"center"}>
        <Typography
          variant="h1"
          fontWeight={700}
          sx={{ fontSize: { xs: "30px", md: "52px" } }}
        >
          Strategic Products
        </Typography>
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{ fontSize: { xs: "28px", md: "52px" } }}
        >
          & Superior Education
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems={"center"}
          sx={{ my: 2 }}
          flexWrap={"wrap"}
        >
          <Typography variant="body2" fontWeight={500} color="textSecondary">
            Made for
          </Typography>
          <img src={etsyIcon} alt="Etsy" height={16} />
          <Typography variant="body2" fontWeight={500} color="textSecondary">
            &
          </Typography>
          <img
            src={amazonIcon}
            alt="Amazon"
            height={16}
            style={{ marginTop: 8 }}
          />
          <Typography variant="body2" fontWeight={500} color="textSecondary">
            Print-on-Demand Sellers
          </Typography>
        </Stack>
        <VoomlyEmbed />
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <NavLink to="/signup">
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              size="large"
              endIcon={<ArrowForward />}
            >
              Access Now
            </Button>
          </NavLink>
        </motion.div>
      </Stack>
    </Container>
  );
}
