import React from "react";
import Iconify from "src/components/iconify";
import { Box, InputAdornment, Stack, TextField } from "@mui/material";

const LinkButtons = ({
  etsyListingID,
  setEtsyListingID,
  amazonListingID,
  setAmazonListingID,
}) => {
  return (
    <Box sx={{ m: 3 }}>
      <Stack
        direction={"column"}
        spacing={3}
        px={4}
        py={2}
        flexWrap={"wrap"}
        textAlign={"left"}
      >
        <TextField
          fullWidth
          variant="standard"
          label="Link To Etsy"
          placeholder="Product Listing ID"
          name="etsy"
          value={etsyListingID}
          onChange={(event) => {
            setEtsyListingID(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="la:etsy" color="#f2521b" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          variant="standard"
          label="Link To Amazon"
          placeholder="Product SKU"
          name="amazon"
          value={amazonListingID}
          onChange={(event) => {
            setAmazonListingID(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="bi:amazon" color="#343b45" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Box>
  );
};

export default LinkButtons;
