import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import MyImage from "../../components/lazy-load-image/MyImage";
import AddTutorials from "src/sections/modals/tutorials/AddTutorials";
import { deleteTutorialsContent, getTutorialsData } from "./requests";
import { useDispatch, useSelector } from "react-redux";
import { StyledLink } from "../auth/login/Login.styles";
import { setTutorialsDetail } from "src/redux/actions/educationActions";
import { setModalState } from "src/redux/actions/modalActions";
import DeleteContent from "src/sections/modals/tutorials/DeleteContent";

const Tutorials = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const tutorialsData = useSelector((state) => state.education.tutorialsData);

  useEffect(() => {
    getTutorialsData(dispatch);
  }, [dispatch, userType]);

  return (
    <Container maxWidth="2xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h4">Tutorials</Typography>
        {userType === "Super Admin" && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => dispatch(setModalState(<AddTutorials />))}
          >
            Add Content
          </Button>
        )}
      </Stack>
      {tutorialsData?.length ? (
        <Grid container spacing={3}>
          {tutorialsData?.map((item) => (
            <Grid key={item.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card onClick={() => dispatch(setTutorialsDetail(item))}>
                <StyledLink to={`/dashboard/tutorials/${item?.id}`}>
                  <Box sx={{ pt: "100%", position: "relative" }}>
                    <MyImage
                      image={item?.image}
                      sx={{
                        top: 0,
                        position: "absolute",
                        width: "100% ",
                        height: "100%",
                      }}
                    />
                  </Box>
                </StyledLink>

                <Stack direction={"row"} spacing={1} sx={{ p: 3 }}>
                  <Typography variant="h6" noWrap>
                    {item?.title}
                  </Typography>

                  {userType === "Super Admin" && (
                    <>
                      <IconButton
                        onClick={() =>
                          dispatch(
                            setModalState(<AddTutorials tutorialsData={item} />)
                          )
                        }
                      >
                        <Iconify icon="eva:edit-fill" color="primary.main" />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          dispatch(
                            setModalState(
                              <DeleteContent
                                onDelete={() =>
                                  deleteTutorialsContent(dispatch, item?.id)
                                }
                              />
                            )
                          )
                        }
                      >
                        <Iconify icon="eva:trash-2-outline" color={"red"} />
                      </IconButton>
                    </>
                  )}
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "50vh" }}
        >
          <Grid item xs={3}>
            There is no content added yet...
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Tutorials;
