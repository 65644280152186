import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Button, Box, Stack } from "@mui/material";
import { endTrial } from "../subscription-management/requests";
import { useNavigate } from "react-router-dom";

const TrialEndCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loadingButton);
  const subscription = useSelector((state) => state.user.subscription);

  const payload = {
    subscription_id: subscription?.subscription_id,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        backgroundColor: "background.default",
        px: 2,
      }}
    >
      <Card
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
          textAlign: "center",
          maxWidth: 500,
          borderRadius: 3,
          boxShadow: 4,
        }}
      >
        <LockIcon sx={{ fontSize: 48, color: "grey.600" }} />
        <Typography variant="h6" fontWeight="bold">
          Our "Trainings" feature is only available <br /> after your 7-day free
          trial ends.
        </Typography>
        <Typography variant="body1" textAlign={"left"} mt={2}>
          Would you like to skip the remainder of your free trial and access our
          Trainings now?
        </Typography>
        <Typography variant="body2" fontWeight={600} textAlign={"left"}>
          By clicking "Yes, Access Trainings Now", your card on file will be
          charged automatically.
        </Typography>
        <Stack direction="row" spacing={2} width="100%" mt={4}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => navigate("/dashboard/app")}
          >
            No, Continue Free Trial
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            onClick={() => endTrial(dispatch, payload)}
          >
            {loading ? "Processing..." : "Yes, Access Trainings Now"}
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};

export default TrialEndCard;
