import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { setModalState } from "src/redux/actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addPlan } from "src/pages/subscription-management/requests";

// Validation schema
const schema = yup.object().shape({
  name: yup.string().required("Plan name is required"),
  desc: yup.string().required("Description is required"),
  price: yup
    .number()
    .transform((value, originalValue) =>
      String(originalValue).trim() === "" ? null : parseFloat(originalValue)
    )
    .typeError("Price must be a valid number")
    .positive("Price must be greater than zero")
    .required("Price is required"),
  interval: yup
    .string()
    .oneOf(["month", "year"], "Select a valid interval")
    .required("Interval is required"),
});

const AddPlan = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    const payload = {
      product_name: data.name,
      product_description: data.desc,
      price: data.price,
      interval: data.interval,
    };
    addPlan(dispatch, payload);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction={"column"} justifyContent={"center"} spacing={2}>
        <Typography variant="h4" gutterBottom>
          Add Subscription Plan
        </Typography>

        <TextField
          fullWidth
          type="text"
          label="Plan Name"
          {...register("name")}
          error={!!errors.name}
        />
        {errors.name && (
          <Typography
            color="error"
            variant="caption"
            style={{ marginTop: "2px" }}
          >
            {errors.name.message}
          </Typography>
        )}

        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Description"
          placeholder="What's included in the plan. Seperate each with a dot."
          multiline
          rows={4}
          {...register("desc")}
          error={!!errors.desc}
        />
        {errors.desc && (
          <Typography
            color="error"
            variant="caption"
            style={{ marginTop: "2px" }}
          >
            {errors.desc.message}
          </Typography>
        )}

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            type="text"
            inputProps={{
              inputMode: "decimal",
              pattern: "[0-9]+([.,][0-9]+)?",
            }}
            {...register("price")}
            error={!!errors.price}
            aria-describedby="price-error"
          />
          {errors.price && (
            <Typography
              id="price-error"
              color="error"
              variant="caption"
              style={{ marginTop: "2px" }}
            >
              {errors.price.message}
            </Typography>
          )}
        </FormControl>

        <Controller
          name="interval"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Interval</InputLabel>
              <Select
                fullWidth
                label="Interval"
                {...field}
                error={!!errors.interval}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Interval
                </MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        {errors.interval && (
          <Typography
            color="error"
            variant="caption"
            style={{ marginTop: "2px" }}
          >
            {errors.interval.message}
          </Typography>
        )}

        <Stack direction="row" justifyContent={"center"} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => dispatch(setModalState(undefined))}
          >
            Cancel
          </Button>
          <Box sx={{ ml: 2 }} />
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddPlan;
