import React from "react";
import { Typography, Button, Stack } from "@mui/material";
import successImage from "../../../assets/home/success.png";
import { NavLink } from "react-router-dom";

const SuccessSection = () => {
  return (
    <Stack
      spacing={4}
      direction="column"
      alignItems="center"
      sx={{
        px: { xs: 2, md: 6 },
        py: { xs: 4, md: 10 },
      }}
    >
      <Typography variant="h2" fontWeight="bold" textAlign="center">
        ICP Path to Success
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <img
          src={successImage}
          alt="success"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Stack>
      <NavLink to="/signup">
        <Button
          variant="contained"
          size="large"
          sx={{
            width: { xs: "100%", sm: "auto" },
          }}
        >
          Become a Part of the Inner Circle
        </Button>
      </NavLink>
    </Stack>
  );
};

export default SuccessSection;
