import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import { setUpdates } from "src/redux/actions/educationActions";
import {
  loadingAction,
  loadingBtn2Action,
  loadingBtnAction,
} from "src/redux/actions/userActions";
import {
  addProductToCart,
  setProductStats,
} from "src/redux/actions/productActions";
import { isEmpty } from "lodash";

export const getPosts = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(`/post`);
    if (res) {
      dispatch(setUpdates(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};

export const submitPost = async (dispatch, formData) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.post(`/post`, formData);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const editPost = async (postId, dispatch, formData) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.post(`/post/${postId}`, formData);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const deletePost = async (postId, dispatch) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/post/${postId}`);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const getCartItems = async (dispatch) => {
  try {
    const res = await request.get(`/cart`);

    if (res) {
      let response = res?.data?.data;

      if (isEmpty(response)) {
        dispatch(addProductToCart(0));
      } else {
        dispatch(addProductToCart(response?.items?.length));
      }
    }
  } catch (e) {
    if (e?.response?.status === 404) {
      dispatch(addProductToCart(0));
    }
  }
};

export const getProductStats = async (dispatch, payload) => {
  try {
    const res = await request.post("/products/stats", payload);

    if (res) {
      dispatch(loadingBtn2Action(false));
      dispatch(setProductStats(res.data.data));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtn2Action(false));
  }
};
