import React from "react";
import Header from "./header";
import Footer from "./footer";
import Hero from "./sections/Hero";
import Stats from "./sections/Stats";
import { Box, Container } from "@mui/material";
import UserStories from "./sections/UserStories";
import VideoSection from "./sections/VideoSection";
import CatalogSlider from "./sections/CatalogSlider";
import SuccessSection from "./sections/SuccessSection";
import EducationSection from "./sections/EducationSection";
import SubscriptionSection from "./sections/SubscriptionSection";
import dottedBackground from "../../assets/home/dotted_background.png";

const styles = {
  backgroundColor: "white",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "10%",
    background: "linear-gradient(to top, #ACE4FC, rgba(255, 255, 255, 0))",
    opacity: 1,
  },
};

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <CatalogSlider />
      <div id="catalogue">
        <VideoSection />
        <Stats />
        <SuccessSection />
      </div>
      <div
        id="education"
        style={{
          backgroundImage: `url(${dottedBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <EducationSection />
      </div>
      <div id="pricing">
        <Container
          maxWidth="xl"
          sx={{ px: { xs: 2, sm: 4, md: 18 }, py: 4, ...styles }}
        >
          <Box sx={{ p: 2, my: { xs: 4, md: 8 } }}>
            <SubscriptionSection />
          </Box>
        </Container>
      </div>
      <div id="testimonials">
        <Container maxWidth="xl" sx={{ py: 4 }}>
          <UserStories />
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Home;
