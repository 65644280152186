import request from "src/utils/request";
import { getAllOrdersList } from ".";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";

export const modifyOrderItems = async (
  dispatch,
  userType,
  orderItemID,
  action
) => {
  dispatch(loadingBtnAction(true));

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  const payload = {
    order_item_id: orderItemID,
    action: action,
  };

  try {
    const res = await request.post("/orders/item/modify", payload);

    if (res) {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      toastify("success", res?.data?.message);
    }
  } catch (e) {
    toastify("error", e.message);
    dispatch(loadingBtnAction(false));
  }
};
