import React from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  XAxis,
} from "recharts";

const ProductsLaunched = () => {
  const productStats = useSelector((state) => state.product.productStats);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sortedData = productStats?.products_launched
    ? [...productStats.products_launched].sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return a.month - b.month;
      })
    : [];

  const formattedData = sortedData.map((item) => ({
    name: `${item.month_name}/${item.year}`,
    total_products: item.total_products,
  }));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const [month, year] = payload[0].payload.name.split("/");
      return (
        <Paper
          sx={{
            p: 1,
            backgroundColor: "#fff",
            color: "#000",
            fontSize: isMobile ? "0.75rem" : "1rem",
          }}
          elevation={3}
        >
          <Typography variant="body2">
            {month} {year}
          </Typography>
          <Typography variant="body2">
            Products Launched: <b>{payload[0].value}</b>
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "15px",
        p: isMobile ? 1 : 2,
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={isMobile ? "body1" : "subtitle1"}>
          Total Products Launched
        </Typography>
      </Stack>
      <Box sx={{ width: "100%", height: isMobile ? 150 : 200, py: isMobile ? 1 : 2 }}>
        {formattedData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={formattedData}>
              <XAxis dataKey="name" tick={{ fontSize: isMobile ? 10 : 12 }} />
              <YAxis tick={{ fontSize: isMobile ? 10 : 12 }} />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <Line
                type="monotone"
                dataKey="total_products"
                stroke="#02B2FE"
                strokeWidth={2}
                dot={{ r: isMobile ? 2 : 3 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: "center", py: isMobile ? 4 : 8 }}>
            No Products Launched yet...
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ProductsLaunched;
