import {
  Tooltip,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import SearchOrder from "src/pages/orders/SearchOrder";
import { StyledRoot } from "./UserListToolbar.styles";
import SearchUser from "src/pages/users/SearchUser";

export default function UserListToolbar({
  numSelected,
  rowCount,
  orderStatus,
  userType,
  onSelectAllClick,
  handleOnHoldOrder,
  handleImagesToDownload,
  user,
  Pagination,
}) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {orderStatus === "on hold" ||
      orderStatus === "awaiting approval" ||
      orderStatus === "awaiting shipment" ||
      orderStatus === "shipped" ? (
        <FormGroup>
          <FormControlLabel
            sx={{ width: "140px" }}
            control={
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            }
            label={numSelected ? `${numSelected} selected ` : "Select all"}
          />
        </FormGroup>
      ) : (
        <></>
      )}
      {numSelected > 0 ? (
        <div>
          {orderStatus === "on hold" || orderStatus === "awaiting approval" ? ( //approve orders
            <Button
              variant="contained"
              size="small"
              onClick={handleOnHoldOrder}
            >
              Approve Orders
            </Button>
          ) : (
            ""
          )}
          {userType === "Super Admin" ? ( //download orders
            orderStatus === "awaiting shipment" || orderStatus === "shipped" ? (
              <IconButton onClick={handleImagesToDownload}>
                <Tooltip title="Download">
                  <Iconify icon="bi:cloud-download" color="#02B2FE" />
                </Tooltip>
              </IconButton>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      ) : user ? (
        <SearchUser />
      ) : (
        <>
          <SearchOrder />
          {Pagination}
        </>
      )}
    </StyledRoot>
  );
}
