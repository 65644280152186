import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateAmount,
  handleImagesToDownload,
  handleOnHoldOrder,
} from "src/components/ordersTable/utils";
import Iconify from "src/components/iconify/Iconify";
import etsy_icon from "../../assets/icons/etsy.svg";
import innercircle_logo from "../../assets/logo/logo-hd.png";
import amazon_icon from "../../assets/icons/Amazon_icon.png";
import DeleteOrder from "src/sections/modals/order/deleteOrder";
import RefundOrder from "src/sections/modals/order/RefundOrder";
import UnlockOrder from "src/sections/modals/order/UnlockOrder";
import { onUnlock } from "./requests/icp";
import { hasNull } from "src/utils/commonFunctions";
import { onDeleteOrder } from "./requests/icp/deleteOrder";
import { setModalState } from "src/redux/actions/modalActions";
import { downloadReciept } from "src/components/ordersTable/ordersReciept";
import ShippingForm from "../cart/shipping/ShippingForm";
import { duplicateOrder } from "./requests/icp/duplicateOrder";
import AddItem from "./actions/AddItem";

export const StyledButton = styled(Button)(() => ({
  borderRadius: 12,
  fontWeight: 800,
}));

const ActionIcons = ({
  row,
  orderStatus,
  selectedOrder,
  setSelected,
  openAddNotePopup,
  notes,
}) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const total = calculateAmount(row);
  const [addressInComplete, setAddressIncomplete] = useState(false);
  const shippingAddress = row?.orderDetails[0]?.shipping_details
    ? JSON.parse(row?.orderDetails[0]?.shipping_details)
    : {};
  const orderTracking = row?.orderDetails?.map((item) => item.tracking_id);
  const orderDate = new Date(row?.date);

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  useEffect(() => {
    if (
      shippingAddress.billToName ||
      shippingAddress.shipToName ||
      shippingAddress.street1
    ) {
      setAddressIncomplete(false);
    } else {
      setAddressIncomplete(true);
    }
  }, [addressInComplete, shippingAddress]);

  function checkOrderDate(orderDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Setting time to midnight for comparison

    const orderDateObj = new Date(orderDate); // Ensure `orderDate` is passed to the function
    const cutoffDate = new Date("2024-09-20");
    cutoffDate.setHours(0, 0, 0, 0);

    if (orderDateObj < cutoffDate) {
      // If the order date is older than 20th Sept 2024
      return false;
    } else if (orderDateObj >= cutoffDate && orderDateObj <= today) {
      // If the order date is today or between cutoff and today
      return true;
    } else {
      // If the order date is in the future
      return false;
    }
  }

  return (
    <Box p={2}>
      <Stack
        direction="row"
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
      >
        {userType === "Seller" && orderStatus === "on hold" ? (
          <AddItem orderID={row?.id} />
        ) : (
          ""
        )}

        {orderStatus === "on hold" ? (
          <Tooltip title="Edit shipping address" arrow>
            <IconButton
              color="inherit"
              onClick={() =>
                dispatch(
                  setModalState(
                    <ShippingForm
                      shippingAddress={shippingAddress}
                      orderID={row?.id}
                    />
                  )
                )
              }
            >
              {addressInComplete ? (
                <Badge badgeContent="!" color="error">
                  <Iconify icon="tabler:home-edit" width={28} height={28} />
                </Badge>
              ) : (
                <Iconify icon="tabler:home-edit" width={28} height={28} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}

        {orderStatus === "in production" ||
        orderStatus === "fulfilled" ||
        orderStatus === "Refunded" ? (
          <Tooltip title="Download receipt" arrow>
            <IconButton onClick={() => downloadReciept(row, dispatch)}>
              <Iconify icon={"akar-icons:reciept"} width={28} height={28} />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}

        {userType === "Super Admin" || userType === "PrintProvider" ? (
          orderStatus === "awaiting shipment" || orderStatus === "shipped" ? (
            <Tooltip title="Download" arrow>
              <IconButton
                onClick={() =>
                  handleImagesToDownload(row, selectedOrder, dispatch)
                }
              >
                <Iconify
                  color="primary.main"
                  icon="bi:cloud-download"
                  width={28}
                  height={28}
                />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {userType === "Seller" && orderStatus === "fulfilled" ? (
          <Tooltip title="Duplicate" arrow>
            <IconButton
              onClick={() => duplicateOrder(dispatch, userType, row?.id)}
            >
              <Iconify
                width={28}
                height={28}
                color="primary.main"
                icon="ion:duplicate"
              />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {userType === "PrintProvider" ? (
          ""
        ) : orderStatus === "awaiting approval" ||
          orderStatus === "awaiting shipment" ||
          (orderStatus === "in production" && row?.admin_approved === 0) ||
          orderStatus === "shipped" ? (
          <Tooltip title="Refund" arrow>
            <IconButton
              onClick={() =>
                dispatch(setModalState(<RefundOrder order={row} />))
              }
            >
              <Iconify
                width={28}
                height={28}
                icon="mdi:credit-card-refund-outline"
                color="primary.main"
              />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {!notes && userType === "Super Admin" && (
          <Tooltip title="Add Notes" arrow>
            <IconButton onClick={openAddNotePopup}>
              <Iconify width={28} height={28} icon="ic:baseline-plus" />
            </IconButton>
          </Tooltip>
        )}

        {orderStatus === "on hold" ||
        orderStatus === "awaiting approval" ||
        orderStatus === "awaiting shipment" ||
        orderStatus === "shipped" ? (
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() =>
                dispatch(
                  setModalState(
                    <DeleteOrder
                      deleteOrder={() =>
                        onDeleteOrder(row.id, userType, dispatch)
                      }
                    />
                  )
                )
              }
            >
              <Iconify
                icon={"material-symbols:delete-outline"}
                width={28}
                height={28}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}

        <img
          src={
            row?.type === "Etsy"
              ? etsy_icon
              : row?.type === "Amazon"
              ? amazon_icon
              : innercircle_logo
          }
          style={{
            width:
              row?.type === "Etsy"
                ? "32px"
                : row?.type === "Amazon"
                ? "32px"
                : "38px",
          }}
          alt="order-type"
        />
      </Stack>

      {orderStatus === "on hold" || orderStatus === "awaiting approval" ? (
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 3 }}
          onClick={() =>
            handleOnHoldOrder(
              row,
              selectedOrder,
              setSelected,
              userType,
              dispatch
            )
          }
        >
          Approve Order
        </Button>
      ) : (
        ""
      )}
      {userType === "PrintProvider" ? (
        ""
      ) : orderStatus === "awaiting shipment" || orderStatus === "shipped" ? (
        <Box sx={{ my: 2 }}>
          <Chip
            color="error"
            variant="outlined"
            label={row?.is_locked === 0 ? "Unlocked" : "Locked"}
            onClick={() =>
              dispatch(
                setModalState(
                  <UnlockOrder
                    row={row}
                    onUnlock={() =>
                      onUnlock(
                        dispatch,
                        userType,
                        page,
                        rowsPerPage,
                        tabValue,
                        row.id
                      )
                    }
                  />
                )
              )
            }
          />
        </Box>
      ) : (
        <></>
      )}

      {checkOrderDate(orderDate) ? (
        orderStatus === "shipped" || orderStatus === "fulfilled" ? (
          <Stack direction={"column"} mt={3}>
            <StyledButton size="small" variant="outlined">
              {hasNull(orderTracking) ? " Partially Shipped" : "Fully Shipped"}
            </StyledButton>
          </Stack>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <Box sx={{ mt: 3 }}></Box>
      {orderStatus === "refunded" && row?.is_refunded === 1 ? (
        <Stack direction={"column"}>
          <StyledButton size="small" variant="outlined">
            {row?.refunded_amount < total
              ? "Partially Refunded"
              : row?.refunded_amount === total
              ? "Fully Refunded"
              : "Refunded"}
            <br />
            Amount: ${row?.refunded_amount}
          </StyledButton>
        </Stack>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ActionIcons;
