import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TotalProductsBarChart = () => {
  const allUserStats = useSelector((state) => state.user.allUserStats);
  const data = allUserStats ? allUserStats?.monthly_sold_products : [];

  const formattedData = data?.map((item) => ({
    ...item,
    total: Number(item.total),
  }));

  const formatDate = (item) => {
    return `${item.month}/${item.year}`;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={formatDate} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TotalProductsBarChart;
