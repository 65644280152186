import { useEffect, useState } from "react";
import UserTable from "./UserTable";
import AllUserStats from "./AllUserStats";
import { isValidPagination } from "./utils";
import { getAllUserStats, getUsers } from "./requests";
import { useDispatch, useSelector } from "react-redux";
import { fShortenNumber } from "src/utils/formatNumber";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { setModalState } from "src/redux/actions/modalActions";
import {
  setSelectedFilter,
  setUserPagination,
} from "src/redux/actions/userActions";
import FilterDropdown from "src/components/filter-dropdown";
import { Stack, Paper, Container, Typography, Box, Tab } from "@mui/material";
import TotalProductsBarChart from "src/sections/modals/user/TotalProductsBarChart";
import TotalAdminRevenueChart from "src/sections/modals/user/TotalAdminRevenueChart";

export default function UserPage() {
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const [userStatus, setUserStatus] = useState("active");

  const userList = useSelector((state) => state.user.userList);
  const userType = useSelector((state) => state.user.user.type);
  const usersMeta = useSelector((state) => state.user.userMeta);
  const userPagination = useSelector(
    (state) => state.user.userPagination ?? [1, 25]
  );
  const selectedFilter = useSelector((state) => state.user.selectedFilter);
  const filterOptions = ["All Users", "Sellers", "Print Providers"];

  useEffect(() => {
    let page = userPagination ? userPagination?.page : 1;
    let rowsPerPage = userPagination ? userPagination?.rowsPerPage : 25;

    getAllUserStats(dispatch);
    getUsers(dispatch, userStatus, page, rowsPerPage, selectedFilter);
    dispatch(setModalState(undefined));

    if (!isValidPagination(userPagination)) {
      dispatch(setUserPagination(1, 25));
    }
  }, [dispatch, value, userStatus, userPagination, selectedFilter]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setSelectedFilter("All Users"));
    if (newValue === "1") {
      setUserStatus("active");
    } else if (newValue === "2") {
      setUserStatus("inactive");
    } else if (newValue === "3") {
      setUserStatus("trial");
    } else if (newValue === "4") {
      setUserStatus("missing_cards");
    }
  };

  const handleFilterChange = (option) => {
    dispatch(setSelectedFilter(option));
  };

  return (
    <Container maxWidth="2xl" sx={{ margin: "0px", padding: "0px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>
      </Stack>

      {userType === "Super Admin" && <AllUserStats />}

      <Paper sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "white",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // wrapped
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                label={`Active Users (${fShortenNumber(
                  usersMeta?.count?.active
                )})`}
                value="1"
              />
              <Tab
                label={`Inactive Users (${fShortenNumber(
                  usersMeta?.count?.inactive
                )})`}
                value="2"
              />
              <Tab
                label={`On Trial Users (${fShortenNumber(
                  usersMeta?.count?.trial
                )})`}
                value="3"
              />
              <Tab
                label={`Account Created (${fShortenNumber(
                  usersMeta?.count?.missing_cards
                )})`}
                value="4"
              />
              <Tab label={`Total products sold per month`} value="5" />
              <Tab label={`Total admin revenue per month`} value="6" />
            </TabList>

            <FilterDropdown
              options={filterOptions}
              selectedOption={selectedFilter}
              onFilterChange={handleFilterChange}
            />
          </Box>

          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="1">
            <UserTable userList={userList} userStatus={"active"} />
          </TabPanel>

          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="2">
            <UserTable userList={userList} userStatus={"inactive"} />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="3">
            <UserTable userList={userList} userStatus={"trial"} />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="4">
            <UserTable userList={userList} userStatus={"account"} />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="5">
            <TotalProductsBarChart />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="6">
            <TotalAdminRevenueChart />
          </TabPanel>
        </TabContext>
      </Paper>
    </Container>
  );
}
