export const formatHistoryData = (data) => {
  const formattedData = [];
  data.forEach((item) => {
    if (item?.order_id) {
      if (item.is_refunded === 0) {
        formattedData.push({
          order_id: item.order_id,
          date: item?.created_at,
          is_refunded: item.is_refunded,
          amount: item?.amount,
          payment_mode: item?.payment_mode,
          refund_amount: null,
          type: "debit",
        });
      } else if (item.is_refunded === 1) {
        formattedData.push(
          {
            order_id: item.order_id,
            date: item?.updated_at,
            is_refunded: item.is_refunded,
            amount: item?.amount,
            payment_mode: item?.payment_mode,
            refund_amount: item?.refund_amount,
            type:
              item?.refund_amount === item?.amount
                ? "full refund"
                : "partial refund",
          },
          {
            order_id: item.order_id,
            date: item?.created_at,
            is_refunded: 0,
            amount: item?.amount,
            payment_mode: item?.payment_mode,
            refund_amount: null,
            type: "debit",
          }
        );
      }
    } else {
      formattedData.push({
        order_id: item.order_id,
        date: item?.created_at,
        is_refunded: item.is_refunded,
        refund_amount: null,
        payment_mode: item?.payment_mode,
        amount: item?.amount,
        type: "Added to wallet",
      });
    }
  });

  formattedData.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  return formattedData;
};
