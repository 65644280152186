import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Button,
  Stack,
  Chip,
  IconButton,
  Divider,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadingBtnAction } from "src/redux/actions/userActions";
import request from "src/utils/request";
import { getProductList } from "../../../pages/products/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import UploadImage from "src/components/upload-image/UploadImage";
import { Delete } from "@mui/icons-material";
import Iconify from "src/components/iconify";
import helloCustomImage from "../../../assets/helloCustomImage.png";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";
import { convertImageToFile } from "src/pages/products/details/utils";

const MAX_SKUS = 5; // Limit to 5 SKUs

const LinkEtsySKU = ({ product, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);
  const [hellocustomChecked, setHellocustomChecked] = useState(false);
  const [skuID, setSkuID] = useState(0);

  const [skus, setSkus] = useState([
    { id: Date.now(), sku: "", graphicImage: [], graphicURL: "" },
  ]);

  const addSKU = () => {
    if (skus.length < MAX_SKUS) {
      setSkus([
        ...skus,
        { id: Date.now(), sku: "", graphicImage: [], graphicURL: "" },
      ]);
    } else {
      toastify("error", "You can only add up to 5 SKUs.");
    }
  };

  const removeSKU = (id) => {
    setSkus(skus.filter((item) => item.id !== id));
  };

  const handleSKUChange = (id, field, value) => {
    setSkus((prev) =>
      prev.map((item) => (item.id === id ? { ...item, [field]: value } : item))
    );
  };

  const handleCheckboxChange = (event, skuId) => {
    const isChecked = event.target.checked;
    setHellocustomChecked(isChecked);
    setSkuID(skuId);
  };

  useEffect(() => {
    if (hellocustomChecked) {
      convertImageToFile(dispatch, helloCustomImage, "sku", setSkus, skuID);
    } else {
      setSkus((prevSkus) =>
        prevSkus.map((sku) =>
          sku.id === skuID
            ? {
                ...sku,
                graphicImage: [],
              }
            : sku
        )
      );
    }
  }, [dispatch, hellocustomChecked, skuID]);

  const getFormValue = (e) => {
    e.preventDefault();

    skus.forEach((skuItem) => {
      const formData = new FormData();
      formData.append("catalog_id", product?.catalog?.id);
      formData.append("product_id", product?.id);
      formData.append("listing_id", product?.listing_id);
      formData.append("sku", skuItem.sku);

      if (skuItem.graphicImage?.length) {
        formData.append("graphic_image", skuItem.graphicImage[0]);
      } else {
        formData.append("url", skuItem.graphicURL);
      }

      linkProductSKU(formData);
    });
  };

  const linkProductSKU = async (payload) => {
    dispatch(loadingBtnAction(true));

    try {
      const res = await request.post("/products/link/etsy/sku", payload);
      if (res) {
        dispatch(loadingBtnAction(false));
        toastify("success", res.data.message);
        dispatch(setModalState(undefined));
        getProductList(dispatch, page + 1, rowsPerPage);
      }
    } catch (e) {
      dispatch(loadingBtnAction(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        LINK ETSY SKU
      </Typography>
      <form onSubmit={getFormValue}>
        <Chip label={`Listing ID: ${product?.listing_id}`} sx={{ mb: 2 }} />

        {skus?.map((item, index) => (
          <Box
            key={item.id}
            sx={{ mb: 3, p: 2, border: "1px solid #ddd", borderRadius: "8px" }}
          >
            <Typography variant="h6">{`SKU #${index + 1}`}</Typography>

            <TextField
              type="text"
              size="small"
              label="Product SKU"
              name="sku"
              required
              fullWidth
              value={item.sku}
              onChange={(e) => handleSKUChange(item.id, "sku", e.target.value)}
              sx={{ mt: 2 }}
            />

            <Stack direction={"row"} alignItems={"center"} mt={2}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Add Graphic Image
                </Typography>

                {item.graphicImage?.length ? (
                  <DeleteImageViewer
                    file={item.graphicImage[0]}
                    fileName={`graphic-${index + 1}`}
                    deleteFile={() =>
                      handleSKUChange(item.id, "graphicImage", [])
                    }
                  />
                ) : (
                  <UploadImage
                    state={item.graphicImage}
                    setState={(value) =>
                      handleSKUChange(item.id, "graphicImage", value)
                    }
                    disable={item.graphicURL?.length ? true : false}
                    clearState={() =>
                      handleSKUChange(item.id, "graphicImage", [])
                    }
                  />
                )}
              </Box>
              <Box sx={{ marginTop: 4 }}>
                <Divider>OR</Divider>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Stack direction="row" alignItems={"center"}>
                  <Typography variant="subtitle2" sx={{ mr: 2 }}>
                    Add Graphic URL
                  </Typography>
                  <img
                    src={helloCustomImage}
                    alt="hellocustom"
                    width={30}
                    height={30}
                  />
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    checked={hellocustomChecked}
                    onChange={(event) => handleCheckboxChange(event, item.id)}
                  />
                </Stack>
                <TextField
                  label="Graphic URL"
                  name="graphicURL"
                  variant="outlined"
                  fullWidth
                  value={item.graphicURL}
                  onChange={(e) =>
                    handleSKUChange(item.id, "graphicURL", e.target.value)
                  }
                  disabled={item.graphicImage?.length ? true : false}
                />
              </Box>
            </Stack>

            {skus.length > 1 && (
              <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
                <IconButton onClick={() => removeSKU(item.id)} color="error">
                  <Delete />
                </IconButton>
              </Stack>
            )}
          </Box>
        ))}

        <Stack direction={"row"} justifyContent={"flex-start"} mb={4}>
          <Button
            variant="outlined"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={addSKU}
            disabled={skus.length >= MAX_SKUS}
          >
            Add SKU
          </Button>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            sx={{ borderRadius: "16", width: "440px" }}
            loading={isLoading}
            loadingIndicator={<CircularProgress size={16} color="inherit" />}
          >
            Link Product SKU
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default LinkEtsySKU;
