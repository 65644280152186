import React, { useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { checkURL, getLink, getUploadedImage } from "../order/utils";
import ImageViewPopup from "src/components/image-viewer/ImageViewPopup";
import { useSelector } from "react-redux";

const SKUTable = () => {
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const skus = catalogDetails?.etsy_product_skus || [];
  const paginatedSkus = skus.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper>
      <TableContainer sx={{ overflowX: "auto" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                "& th:first-of-type": {
                  borderTopLeftRadius: "16px",
                },
                "& th:last-of-type": {
                  borderTopRightRadius: "16px",
                },
              }}
            >
              <TableCell align="center">SKU #</TableCell>
              <TableCell align="center">Preview Image</TableCell>
              <TableCell align="center">Graphic</TableCell>
              {catalogDetails?.catalog?.no_of_graphics === 2 && (
                <TableCell align="center">Notecard Graphic</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedSkus.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="center" sx={{ p: 0 }}>
                  <h5>{item?.sku}</h5>
                </TableCell>
                <TableCell align="center">
                  <ImageViewPopup
                    imageSRC={
                      item?.preview_image === null
                        ? catalogDetails?.catalog?.images
                        : [item?.preview_image]
                    }
                    overlap
                  />
                </TableCell>
                <TableCell align="center">
                  {checkURL(item?.graphic_image) === "url" ? (
                    <center>
                      <a
                        href={getLink(item?.graphic_image)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="contained" size="small">
                          Open Graphic
                        </Button>
                      </a>
                    </center>
                  ) : (
                    <center>
                      <ImageViewPopup
                        imageSRC={[getUploadedImage(item, "graphic")]}
                        overlap
                      />
                    </center>
                  )}
                </TableCell>
                {catalogDetails?.catalog?.no_of_graphics === 2 && (
                  <TableCell align="center">
                    {checkURL(item?.notecard_image) === "url" ? (
                      <center>
                        <a
                          href={getLink(item?.notecard_image)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button variant="contained" size="small">
                            Edit Graphic
                          </Button>
                        </a>
                      </center>
                    ) : (
                      <center>
                        <ImageViewPopup
                          imageSRC={[getUploadedImage(item, "graphic")]}
                          overlap
                        />
                      </center>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10]}
        component="div"
        count={skus.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default SKUTable;
