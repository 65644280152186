import React from "react";
import { Alert, Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import arrow from "../../../assets/arrow.svg";
import offer from "../../../assets/offer.webp";

const AnnualPlanOffer = ({ verifyPromoCode }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton2);

  return (
    <Box sx={{ p: 2 }}>
      <Stack
        direction={{ sm: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ sm: "flex-start", lg: "center" }}
        spacing={1}
      >
        <Box textAlign={"left"} mb={{ xs: 2, lg: 0 }}>
          {[
            "For a Limited Time Use",
            <>
              Code{" "}
              <Box
                component="span"
                sx={{ color: "primary.main", fontWeight: 800 }}
              >
                “Unlock”
              </Box>{" "}
              & Get Our
            </>,
            <>
              Annual Plan for Only{" "}
              <Box
                component="span"
                sx={{ color: "primary.main", fontWeight: 800 }}
              >
                $199!
              </Box>
            </>,
          ].map((text, index) => (
            <Typography
              key={index}
              variant="h4"
              fontWeight={800}
              sx={{
                fontFamily: '"League Spartan", sans-serif',
                lineHeight: 1.2,
              }}
            >
              {text}
            </Typography>
          ))}
        </Box>
        <Box
          component="img"
          src={offer}
          alt="offer"
          sx={{ maxWidth: 350, width: "100%" }}
        />
      </Stack>

      <Typography variant="h6" fontWeight="bold" mt={4} textAlign={"left"}>
        Annual subscribers get{" "}
        <Typography component="span" color="primary.main" fontWeight="bold">
          EXCLUSIVE
        </Typography>{" "}
        access to Jesse through weekly Q&A sessions with other high-level
        members!
      </Typography>

      <Typography mt={2} color="text.secondary" textAlign={"left"}>
        That is <strong>52 Q&A sessions</strong> to ask vital questions and grow
        your POD business faster than ever before.
      </Typography>

      <Typography mt={2} textAlign={"left"}>
        <b>Please note:</b> This feature is <strong>NOT</strong> available for
        monthly subscribers.
      </Typography>

      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        mt={4}
      >
        <Typography
          variant="body1"
          sx={{
            fontFamily: '"DM Sans", sans-serif',
            fontSize: { xs: "13px", md: "16px" },
          }}
        >
          Switch to Annual Plan Instead?
        </Typography>
        <Box
          component="img"
          src={arrow}
          alt="arrow"
          sx={{ width: 40, height: 50 }}
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 2,
          mt: { sm: 2, md: 0 },
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          onClick={() => dispatch(setModalState(undefined))}
          color="inherit"
          sx={{ borderRadius: "20px" }}
        >
          No Thanks
        </Button>
        <Button
          variant="contained"
          onClick={(e) => verifyPromoCode(e, "Unlock")}
          sx={{ borderRadius: "20px", fontWeight: "bold" }}
        >
          {loading ? "Loading..." : "Yes Please!"}
        </Button>
      </Box>
    </Box>
  );
};

export default AnnualPlanOffer;
