import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Button,
  Typography,
  ListItem,
  Card,
  CardContent,
} from "@mui/material";
import SubscriptionCard from "src/components/subscription-card/SubscriptionCard";
import {
  createSubscription,
  endTrial,
} from "src/pages/subscription-management/requests";
import { setModalState } from "src/redux/actions/modalActions";
import CancelSubscription from "src/sections/modals/subscription/CancelSubscription";

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);
  const subscription = useSelector((state) => state.user.subscription);

  const cancelPlanPayload = {
    plan_id: subscription?.plan?.id,
  };

  const endTrialPayload = {
    subscription_id: subscription?.subscription_id,
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          {subscription !== null ? (
            <SubscriptionCard
              item={subscription?.plan}
              status={subscription?.status}
              discount={subscription?.discounted_price}
              onCancel={() => dispatch(setModalState(<CancelSubscription />))}
              resubscribe={() =>
                createSubscription(dispatch, cancelPlanPayload)
              }
            />
          ) : (
            <SubscriptionCard
              item={{
                name: "Free Plan",
                description:
                  "This is Free plan for ICP sellers. Can be subscribed to paid plan for premium features",
              }}
            />
          )}
        </Grid>
        {subscription?.is_trial === "yes" && (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  End Free Trial
                </Typography>
                <Typography variant="body2" textAlign={"left"}>
                  By clicking "End Trial", your card on file will be charged
                  automatically.
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => endTrial(dispatch, endTrialPayload)}
                >
                  {loading ? "Loading..." : "End Trial"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default SubscriptionPlan;
