import React from "react";
import Iconify from "src/components/iconify";
import { SmallImage } from "src/theme/globalStyles";
import { checkURL, getLink, getUploadedImage } from "./utils";
import { Box, Button, Stack, Typography } from "@mui/material";

const GraphicComponent = ({ title, image, item, type }) => {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      spacing={2}
      justifyContent={"space-between"}
      mb={3}
    >
      <Typography variant="subtitle1" sx={{ my: 2 }}>
        {title}
      </Typography>
      {checkURL(image) === "url" ? (
        <Button
          variant="contained"
          size="small"
          endIcon={<Iconify icon={"mi:arrow-right-up"} />}
          onClick={() => {
            if (image) {
              window.open(getLink(image), "_blank");
            }
          }}
        >
          Edit Graphic
        </Button>
      ) : (
        <SmallImage
          image={getUploadedImage(item, type)}
          component={"img"}
          alt={type}
        />
      )}
    </Stack>
  );
};

const OrderGraphics = ({ item }) => {
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        {item?.catalog?.title} Graphics
      </Typography>
      <GraphicComponent
        title={"Graphic Image"}
        image={item?.graphic_image}
        item={item}
        type={"graphic"}
      />

      {item?.notecard_image && (
        <GraphicComponent
          title={"Notecard Image"}
          image={item?.notecard_image}
          item={item}
          type={"notecard"}
        />
      )}
    </Box>
  );
};

export default OrderGraphics;
