import React from "react";
import { useSelector } from "react-redux";
import { Box, CardMedia, Container, Stack, Typography } from "@mui/material";
import { Markup } from "interweave";
import { NavLink } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";

const TutorialDetail = () => {
  const tutorialsDetail = useSelector(
    (state) => state.education.tutorialsDetail
  );

  return (
    <Container maxWidth="2xl">
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} spacing={1} alignItems={"center"} mb={2}>
          <NavLink to={"/dashboard/tutorials"}>
            <ArrowBackIos />
          </NavLink>
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 4 }}>
            {tutorialsDetail?.title}
          </Typography>
        </Stack>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
          }}
        >
          <CardMedia
            component="iframe"
            src={tutorialsDetail?.link}
            sx={{
              width: "100%",
              height: "400px",
              borderRadius: "8px",
            }}
            frameBorder="0"
            allowFullScreen
          />
        </Box>
        <Typography variant="body1" sx={{ color: "text.secondary", my: 4 }}>
          <Markup content={tutorialsDetail?.description} />
        </Typography>
      </Box>
    </Container>
  );
};

export default TutorialDetail;
