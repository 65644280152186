import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/logo/innerlogo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const navItems = ["Education", "Pricing", "Testimonials"];

export default function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (id) => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true });
      setTimeout(() => scrollToSection(id), 100);
    } else {
      scrollToSection(id);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id.toLowerCase());
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const drawer = (
    <Box
      sx={{ width: 250, color: "#000" }}
      role="presentation"
      onClick={handleDrawerToggle}
    >
      <List>
        <NavLink
          to="/catalog"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <ListItem>
            <ListItemText primary={"Catalog"} />
          </ListItem>
        </NavLink>
        {navItems?.map((item) => (
          <ListItem key={item} onClick={() => handleNavigation(item)}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
        <NavLink to={"/login"} color="inherit">
          <ListItem>
            <ListItemText primary={"Login"} />
          </ListItem>
        </NavLink>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white !important",
        boxShadow: "none",
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: "black",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavLink to={"/"}>
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: 60,
                  marginRight: 8,
                  marginTop: 8,
                  marginBottom: 8,
                }}
              />
            </NavLink>
          </Typography>

          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 4 }}>
            <NavLink to={"/catalog"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                sx={{
                  cursor: "pointer",
                  color: "#000",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Catalog
              </Typography>
            </NavLink>
            {navItems.map((item) => (
              <Typography
                key={item}
                variant="body1"
                sx={{
                  cursor: "pointer",
                  color: "#000",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => handleNavigation(item)}
              >
                {item}
              </Typography>
            ))}
          </Box>

          <NavLink to={"/signup"}>
            <Button
              variant="contained"
              size="small"
              sx={{
                mx: { xs: 2, md: 4 },
                fontSize: { xs: "10px", md: "" },
              }}
            >
              Sign up
            </Button>
          </NavLink>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <NavLink
              to={"/login"}
              color="inherit"
              style={{ textDecoration: "none" }}
            >
              Login
            </NavLink>
          </Box>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, color: "black" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>

      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </AppBar>
  );
}
