import React, { useEffect, useState } from "react";
import UserForm from "./steps/UserForm";
import PasswordForm from "./steps/PasswordForm";
import SubscriptionForm from "./steps/SubscriptionForm";
import logo from "../../../assets/logo/logo.png";
import { Box, Card, CardContent, Container } from "@mui/material";
import { useParams } from "react-router-dom";

const SignupPage = () => {
  const { mode } = useParams();
  const [step, setStep] = useState("user");
  const [userData, setUserData] = useState({});
  const registered = localStorage.getItem("registered");

  useEffect(() => {
    if (registered === "true" || mode === "subscribe") {
      setStep("subscribe");
    }
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth:"520px",
        bgcolor: "#f9fafc",
        my: { xs: 4, md: 8 },
        pb: { xs: 4, md: 8 },
        px: { xs: 2, md: 0 },
      }}
    >
      <Card
        sx={{
          textAlign: "center",
          borderRadius: "8px",
          boxShadow: "0 5px 10px  #eff1f5",
          border: "1px solid #edf2f9",
          width: "100%",
        }}
      >
        <CardContent>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: { xs: 2, md: 4 },
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                height: 40,
              }}
            />
          </Box>

          {step === "user" ? (
            <UserForm setStep={setStep} setUserData={setUserData} />
          ) : step === "password" ? (
            <PasswordForm setStep={setStep} userData={userData} />
          ) : step === "subscribe" ? (
            <SubscriptionForm />
          ) : null}
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignupPage;
