export const setModalState =
  (component, boolean = false, customStyles = {}) =>
  async (dispatch) => {
    dispatch({
      type: "openModal",
      payload: {
        openModal: component,
        isFullScreen: boolean,
        customStyles: customStyles,
      },
    });
  };
