import React from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "src/components/button/StyledButton";
import AddIcon from "@mui/icons-material/Add";
import { loadingBtn2Action } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";
import request from "src/utils/request";
import { linkAmazonProduct, linkEtsyProduct } from "../utils";
import { isEmpty } from "lodash";

const AddToProducts = ({
  disabled,
  productTitle,
  reset,
  etsyListingID,
  amazonListingID,
}) => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const AddProductLoading = useSelector((state) => state.user.loadingButton2);
  const previewImageSRC = useSelector((state) => state.product.previewImageSRC);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);
  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );

  const type = catalogDetails?.breadCrumb;
  const noOfGraphics =
    type === "products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const addToProducts = async () => {
    let formData = new FormData();
    formData.append("catalog_id", catalogDetails.id);
    formData.append("quantity", "1");
    formData.append("title", productTitle);
    formData.append("price", catalogDetails.price);
    formData.append("description", catalogDetails.description);

    if (!isEmpty(previewImageSRC?.filename)) {
      formData.append("preview_image", previewImageSRC?.src);
    }

    if (graphicURL) {
      formData.append("url", graphicURL);
    } else {
      formData.append("graphic_image", uploadedGraphicImage?.src);
    }

    if (noOfGraphics === 2) {
      if (notecardURL) {
        formData.append("notecard_url", notecardURL);
      } else {
        formData.append("notecard_image", uploadedNotecardImage?.src);
      }
    }

    dispatch(loadingBtn2Action(true));
    try {
      const res = await request.post(`/products`, formData);

      if (res) {
        reset();
        toastify("success", res.data.message);
        dispatch(loadingBtn2Action(false));
        const productID = res?.data?.data?.id;
        if (etsyListingID) {
          let formData = new FormData();
          formData.append("product_id", productID);
          formData.append("listing_id", etsyListingID);
          linkEtsyProduct(formData);
        }
        if (amazonListingID) {
          let formData = new FormData();
          formData.append("product_id", productID);
          formData.append("amazon_sku", amazonListingID);
          linkAmazonProduct(formData);
        }
      }
    } catch (e) {
      dispatch(loadingBtn2Action(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <StyledButton
      title={"Add to Products"}
      disabled={disabled}
      onClick={addToProducts}
      loading={AddProductLoading}
      startIcon={<AddIcon />}
    />
  );
};

export default AddToProducts;
