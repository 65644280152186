import { combineReducers } from "redux";
import modalReducer from "./modal/modalReducer";
import orderReducer from "./order/orderReducer";
import productReducer from "./product/productReducer";
import userReducer from "./user/userReducer";
import paymentReducer from "./payment/paymentReducer";
import OAuthReducer from "./oAuth/oAuthReducer";
import educationReducer from "./education/educationReducer";

const RootReducer = combineReducers({
  user: userReducer, //user
  orders: orderReducer,
  modal: modalReducer,
  product: productReducer,
  payment: paymentReducer,
  oAuth: OAuthReducer,
  education: educationReducer,
});

const rootReducer = (state, action) =>
  RootReducer(action.type === "Logout" ? undefined : state, action);
export default rootReducer;
