import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Box,
  Link,
  Divider,
  Stack,
  CircularProgress,
} from "@mui/material";
import { YouTube } from "@mui/icons-material";
import FooterContent from "src/sections/footer";
import fullLogo from "../../assets/logo/full-logo.png";
import { subscribe } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const styles = {
  backgroundColor: "white",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "25%",
    background: "linear-gradient(to bottom, #ACE4FC, rgba(255, 255, 255, 0))",
    opacity: 1,
  },
};

const Footer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const isLoading = useSelector((state) => state.user.loadingButton);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      alert("Please enter a valid email address.");
      return;
    }

    const payload = {
      email: email,
    };
    subscribe(dispatch, payload);
    setEmail("");
  };

  return (
    <Box sx={{ ...styles, py: 2 }}>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={4}
          mt={4}
        >
          <Grid item xs={12} md={3}>
            <img src={fullLogo} height={48} alt="full-logo" />
            <Typography variant="body1" sx={{ mt: 1 }}>
              10807 E Montgomery Dr
            </Typography>
            <Typography variant="body1">Spokane Valley, WA 99206</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Chat@innercircleprints.com
            </Typography>
          </Grid>

          <Grid item xs={12} md={4.2} textAlign="left">
            <Typography variant="h4" fontWeight={600}>
              Get the latest updates
            </Typography>
            <Typography variant="body1">
              Subscribe to our email list to be the 1st to hear about new
              product launches and major updates to ICP!
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ flexGrow: 1, maxWidth: "250px" }}
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress color="inherit" size={16} />
                  }
                >
                  Subscribe
                </LoadingButton>
              </Box>
            </form>
            <Link
              href="https://www.youtube.com/@jesseteaches"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <YouTube />
                <Typography variant="body1" color={"primary.main"}>
                  Find us on Youtube
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Divider orientation="horizontal" sx={{ my: 2 }} color="#E1E4ED" />
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        <FooterContent />
      </Stack>
    </Box>
  );
};

export default Footer;
