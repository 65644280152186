import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import etsyIcon from "../../../assets/etsy.png";
import amazonIcon from "../../../assets/amazon.png";

const Stats = () => {
  return (
    <Box sx={{ backgroundColor: "#EAF8FF", py: 4, textAlign: "center", mt: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3,  }}>
        Join the New Era of Successful Print-on-Demand Entrepreneurs
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            700,000+
          </Typography>
          <Typography variant="body2" component="div">
          Orders Fulfilled on
            <img
              src={etsyIcon}
              alt="Etsy"
              height={20}
              style={{ verticalAlign: "middle", display: "inline-block" }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            300,000+
          </Typography>
          <Typography variant="body2">
          Orders Fulfilled on
            <img
              src={amazonIcon}
              alt="Amazon"
              height={20}
              style={{
                verticalAlign: "middle",
                display: "inline-block",
                marginTop: 8,
              }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            Rated 4.9+
          </Typography>
          <Typography variant="body2">
            Reviews on{" "}
            <span style={{ color: "#00B67A", fontWeight: "bold" }}>★</span>
            Trustpilot
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stats;
