import React from "react";
import { isEmpty } from "lodash";
import { Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ImageViewPopup from "../image-viewer/ImageViewPopup";
import UpdateVariations from "src/sections/modals/order/variations/updateVariations";

const Variations = ({ item }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);

  let formatted_variations =
    item?.catalog?.id === 39
      ? item?.personalization_details?.filter(
          (item) => item.formatted_name !== "Size"
        )
      : item?.personalization_details;

  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
    >
      <Box>
        {Array.isArray(item?.personalization_details) ? (
          formatted_variations?.map((variation, index) =>
            variation?.value_id === 4 ? (
              !isEmpty(variation?.formatted_value) ? (
                <>
                  <Typography key={index} variant="body2">
                    <b>{variation?.formatted_name}</b>:
                    <p style={{ margin: 0 }}>
                      {" "}
                      {typeof variation?.formatted_value === "string"
                        ? ""
                        : variation?.formatted_value
                            ?.map((item) => item.name)
                            .join(", ")}
                    </p>
                  </Typography>
                  <Typography variant="subtitle2">
                    Total: ${variation?.formatted_value?.length * 0.89}
                  </Typography>
                </>
              ) : (
                ""
              )
            ) : variation?.formatted_name === "Initials" ? (
              variation?.formatted_value ? (
                <Box sx={{ my: 1 }} key={index}>
                  <Typography variant="body2">
                    <b>{variation?.formatted_name}</b>:{" "}
                  </Typography>
                  <Stack direction={"row"} mt={1} flexWrap={"wrap"}>
                    <ImageViewPopup
                      imageSRC={variation?.formatted_value}
                      sx={{ width: "20px", height: "20px", m: 1 }}
                    />
                  </Stack>
                  <Typography variant="subtitle2">
                    Total: ${variation?.formatted_value?.length * 1.89}
                  </Typography>
                </Box>
              ) : (
                ""
              )
            ) : variation?.formatted_name === "sku" ? (
              ""
            ) : variation?.formatted_value ? (
              <Typography key={index} variant="body2">
                <b>{variation?.formatted_name}</b>: {variation?.formatted_value}{" "}
              </Typography>
            ) : (
              ""
            )
          )
        ) : (
          <Typography variant="body2">
            {item?.personalization_details ? (
              <>
                <b>Personalization:</b> {item?.personalization_details}
              </>
            ) : (
              ""
            )}
          </Typography>
        )}
      </Box>
      {userType === "Seller" && item.order_status === "on_hold" && (
        <IconButton
          onClick={() =>
            dispatch(setModalState(<UpdateVariations item={item} />))
          }
        >
          <Edit />
        </IconButton>
      )}
    </Stack>
  );
};

export default Variations;
