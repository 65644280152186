import * as React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";

export default function ProductDescription({ catalogDetails }) {
  const theme = useTheme();

  const type = catalogDetails?.breadCrumb;
  const desc = catalogDetails?.description || [];
  const price = parseFloat(catalogDetails?.price).toFixed(2);

  return (
    <Box sx={{ width: "fit-content", p: 0, m: 0 }}>
      <Typography component="div" variant="h3">
        {catalogDetails?.title}
      </Typography>

      <Typography
        sx={{
          color: theme.palette.primary.main,
          paddingTop: theme.spacing(1),
        }}
        variant="h4"
        color="text.secondary"
        component="div"
      >
        Price: ${price}
      </Typography>

      {desc?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {desc?.map((point, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText primary={`• ${point}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {type !== "products" && (
        <Box sx={{ my: 3 }}>
          <Typography variant="h6">Return Address</Typography>
          <Typography variant="body2">
            10807 E Montgomery Drive
            <br />
            Suite 8<br />
            Spokane Valley, WA 99206
          </Typography>
        </Box>
      )}

      {catalogDetails?.print_provider && (
        <Box sx={{ my: 3 }}>
          <Typography variant="h6">ICP Partner</Typography>
          <Typography variant="body2">
            <b>Name:</b> {catalogDetails?.print_provider?.name}
          </Typography>
          {catalogDetails?.print_provider?.shipping_details && (
            <Typography variant="body2">
              <b>Ship From:</b>{" "}
              {catalogDetails?.print_provider?.shipping_details}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
