import React from "react";
import SKUTable from "./SKUTable";
import request from "src/utils/request";
import { LoadingButton } from "@mui/lab";
import { toastify } from "src/utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { getProductList } from "../../../pages/products/request";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";

const UnLinkEtsySKU = ({ product, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);

  const getFormValue = (e) => {
    e.preventDefault();
    const sku = e.target.elements.sku.value;
    let formData = new FormData();
    formData.append("product_id", product?.id);
    formData.append("sku", sku);
    unlinkProductSKU(formData);
  };
  const unlinkProductSKU = async (payload) => {
    dispatch(loadingBtnAction(true));

    try {
      const res = await request.post("/products/unlink/etsy/sku", payload);
      if (res) {
        dispatch(loadingBtnAction(false));
        toastify("success", res.data.message);
        dispatch(setModalState(undefined));
        getProductList(dispatch, page + 1, rowsPerPage);
      }
    } catch (e) {
      dispatch(loadingBtnAction(false));
      toastify("error", e.response.data.message);
    }
  };
     
  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Unlink an Etsy SKU
      </Typography>

      <SKUTable product={product} />
      <Box sx={{ my: 4 }}></Box>
      <form onSubmit={(e) => getFormValue(e)}>
        <TextField
          fullWidth
          type="text"
          label="Unlink Product SKU"
          name="sku"
          required
        />
        <Box sx={{ my: 4 }}></Box>
        <LoadingButton
          sx={{ borderRadius: "16" }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          loadingIndicator={<CircularProgress size={16} color="inherit" />}
        >
          Unlink SKU
        </LoadingButton>
      </form>
    </Box>
  );
};

export default UnLinkEtsySKU;
